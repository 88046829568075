import {useState} from "react";
import {Col, Row} from "antd";
import {useTranslation} from "react-i18next";

type Props = {
    onChange: (type: string) => void
}

export default function SelectUserType(props: Props) {
    const [selectedType, setSelectedType] = useState('')
    const { t } = useTranslation()
    const cardStyle = {
        maxWidth: 300,
        height: 200,
        borderRadius: 10,
        padding: 15,
        color: 'white',
        cursor: 'pointer',
        fontSize: 16,
        marginBottom: 15
    }

    const selectType = (type) => {
        setSelectedType(type)
        props.onChange(type)
    }

    return (
        <div style={{marginTop: 50}}>
            <Row><h3 style={{margin: 'auto', fontSize: 20}}>{t('auth.register.selectType')}</h3></Row>
            <Row align={'middle'} gutter={80} style={{marginTop: 80}}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div style={{...cardStyle, backgroundColor: '#E87E4D'}} onClick={() => selectType('producer')}>
                        <div>{t('auth.register.producerDescription')}</div>
                        <div style={{bottom: 18, position: 'absolute'}}>{t('auth.register.youAre')}<span style={{fontWeight: 'bold', fontSize: 18}}>{t('profile.producer').toUpperCase()}</span></div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div style={{ ...cardStyle, backgroundColor: '#023047'}} onClick={() => selectType('collector')}>
                        <div style={{marginTop: 30}}>{t('auth.register.collectorDescription')}</div>
                        <div style={{bottom: 18, position: 'absolute'}}>{t('auth.register.youAre')}<span style={{fontWeight: 'bold', fontSize: 18}}>{t('profile.collector').toUpperCase()}</span></div>
                    </div>
                </Col>
            </Row>
        </div>

    )
}