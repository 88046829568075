import React, {useRef, useState} from 'react';
import {Row} from "antd";

export default function FormModel({children}) {
    const formModelRef = useRef();
    const height = (formModelRef?.current?.clientHeight > 600 ) ? formModelRef?.current?.clientHeight : 600
    return(
        <div style={{minHeight: height + 30, width: '100%', backgroundColor:'#023047'}}>
            <div style={{width: '100%', height: 300, backgroundImage: 'linear-gradient(to top left, #023047 50%, #F5F5F5 50%)'}}>
                <div ref={formModelRef} style={{zIndex: 100, marginLeft: '20%', marginRight: '20%'}}>
                    <div style={{
                        minHeight: 500,
                        backgroundColor: 'white',
                        borderRadius: 20,
                        WebkitBoxShadow: '5px 5px 15px 5px #000000',
                        boxShadow: '5px 5px 15px 5px #000000'
                    }}>
                        <Row style={{padding: 25, height: '100%'}} align="middle">
                            <div style={{margin: 'auto', minWidth: '25%'}}>
                                {children}
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}