import React, {useEffect, useState} from 'react';
import {Col, notification, Row, Switch, Table} from "antd";
import API from "../services/API";
import {CheckOutlined, CloseOutlined, PlusCircleOutlined} from "@ant-design/icons";
import ModalCreateMineral from "./ModalCreateMineral";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export default function StockList () {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [stock, setStock] = useState([])
    const [locations, setLocations] = useState([])
    const [mineralTypes, setMineralsType] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [columns] = useState([
        {
            title: t('profile.stock.table.mineral'),
            dataIndex: "type_name",
            key: "type_name",
            render: (_, { type }) => (
                <>
                    {type.alias}
                </>
            ),
        },
        {
            title: t('profile.stock.table.location'),
            dataIndex: "location",
            key: "location",
            render: (_, { from }) => (
                <>
                    {from.title}
                </>
            ),
        },
        {
            title: t('profile.stock.table.quantity'),
            dataIndex: "quantity",
            key: "quantity",
            render: (_, { quantity }) => (
                <>
                    {quantity} KG
                </>
            ),
        },
        {
            title: t('profile.stock.table.quality'),
            dataIndex: "quality",
            key: "quality",
            render: (_, { quality }) => (
                <>
                    {quality.score}
                </>
            ),
        },
        {
            title: t('profile.stock.table.needToPickup'),
            dataIndex: "needToPickup",
            key: "needToPickup",
            render: (_, {id, needToPickup }) => (
                <>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={needToPickup}
                        onChange={() => setNeedToPickup(id, !needToPickup)}
                    />
                </>
            ),
        },
        {
            dataIndex: "needToPickup",
            key: "action",
            render: (_, { id }) => (
                <>
                    <a href={'/mineral/historic/'+id}>Voir l'historique</a>
                </>
            ),
        }
    ])

    useEffect(() => {
        getMineralType().then()
        getMyStock().then()
        getMyLocation().then()
    }, [])

    const getMyStock = async() => {
        try {
            const stock = await API.getMyStock()
            setStock(stock)
        } catch (e) {
            console.warn(e)
        }
    }
    const getMyLocation = async() => {
        try {
            const locations = await API.getMyLocations()
            setLocations(locations)
        } catch (e) {
            console.warn(e)
        }
    }
    const getMineralType = async() => {
        try {
            const mineralTypes = await API.getMineralType()
            setMineralsType(mineralTypes)
        } catch (e) {
            console.warn(e)
        }
    }

    const setNeedToPickup = async (mineralId, newValue) => {
        try {
            await API.putMineralNeedToPickup(mineralId, newValue, async () => await getMyStock())
        } catch (e) {
            console.warn(e)
        }
    }

    return(
        <div style={{marginRight: 35, marginLeft: 35, marginTop: 30}}>
            <Row justify="space-between" align="middle">
                <Col span={8}>
                    <h3 style={{fontSize: 36, fontWeight: 700}}>{t('profile.stock.current-stock')}</h3>
                </Col>
                <Col span={2} offset={14} style={{color: '#E87E4D', fontSize: 24, fontWeight: 700}}>
                    {t('common.add')} <PlusCircleOutlined style={{cursor: 'pointer'}} onClick={() => {
                        if (locations.length > 0) setShowModal(true)
                        else notification.error({message: "You need to create an address before add minerals"})
                }}/>
                </Col>
            </Row>
            <Table columns={columns} dataSource={stock} rowKey={'id'} />
            <ModalCreateMineral
                mineralTypes={mineralTypes}
                visible={showModal}
                onCancel={() => setShowModal(false)}
                locations={locations} onOk={() => {
                    getMyStock().then()
                    notification.success({message: 'Success create'})
                    setShowModal(false)
                }}
            />
        </div>
    )
}