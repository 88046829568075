import {Button, Form, Input, InputNumber, Modal, notification, Row, Select} from "antd"
import {CloseOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import API from "../services/API";
import {useTranslation} from "react-i18next";

export default function ModalCreateMineral({visible, onOk = null, onCancel, locations, mineralTypes}) {
    const [form] = Form.useForm();
    const {t} = useTranslation()

    const onFinish = async(values) => {
        try {
            await API.addMineral({typeId: values.mineralTypeId, locationId: values.addressId, quantity: values.quantity})
            onOk && onOk()
        } catch (error) {
            console.warn(error)
        }
    }

    return(
        <Modal
            visible={visible}
            centered
            onCancel={() => {
                form.resetFields();    //reset form
                onCancel()
            }}
            bodyStyle={{
                paddingTop: 40,
                borderRadius: 20,
                WebkitBoxShadow: '5px 5px 15px 5px #000000',
                boxShadow: '5px 5px 15px 5px #000000'
            }}
            footer={null}
            className={"modalStyle"}
            closeIcon={<CloseOutlined style={{color: 'white'}}/>}
        >
            <div style={{zIndex: 100, marginLeft: '5%', marginRight: '5%'}}>
                <div style={{
                    backgroundColor: 'white',
                    borderRadius: 20,
                    WebkitBoxShadow: '5px 5px 15px 5px #000000',
                    boxShadow: '5px 5px 15px 5px #000000'
                }}>
                    <Row style={{padding: 25, height: '100%'}} align="middle">
                        <div style={{margin: 'auto', minWidth: '25%'}}>
                            <Row>
                                <h4 style={{margin: 'auto'}}>{t('profile.stock.form.title')}</h4>
                            </Row>
                            <Form
                                style={{marginTop: 10}}
                                name="create_mineral"
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                initialValues={{addressId:  locations.length>0 ? locations[0].id:null, mineralTypeId:  mineralTypes.length>0 ? mineralTypes[0].id:null}}
                            >
                                <Form.Item
                                    label={t('profile.stock.form.address')}
                                    name="addressId"
                                >
                                    <Select>
                                        {
                                            locations.map(location => <Select.Option key={location.id} value={location.id}>{location.title}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={t('profile.stock.form.mineral')}
                                    name="mineralTypeId"
                                >
                                    <Select >
                                        {
                                            mineralTypes.map(mineral => <Select.Option key={mineral.id} value={mineral.id}>{mineral.alias}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={t('profile.stock.form.quantity')}
                                    name="quantity"
                                    rules={[{ required: true, message:  'require' }]}
                                    initialValue={100}
                                >
                                    <InputNumber addonAfter="KG"/>
                                </Form.Item>

                                <Form.Item style={{backgroundColor: '#023047'}} wrapperCol={{ offset: 8, span: 7 }} >
                                    <Button type="text" htmlType="submit" style={{color: 'white'}}>
                                        {t('common.add')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Row>
                </div>
            </div>
        </Modal>
    )
}