import * as React from "react";
import { Routes, Route,  } from "react-router-dom";
import { Redirect } from "react-router";
import "./App.css";
import Home from '../containers/pages/home'
import Register from '../containers/pages/auth/register/register';
import NoMatch404 from '../containers/pages/no-match-404';
import Login from '../containers/pages/auth/login'
import { PrivateRoute } from '../utils/privateRoute';
import Logout from '../containers/pages/auth/logout'
import Marketplace from "../containers/pages/marketplace";
import MakeProposal from "../containers/pages/proposal/makeProposal";
import MyAddress from "../containers/pages/location/my-address";
import MyProduct from "../containers/pages/product/my-product";
import Profile from "../containers/pages/profile/profile";
import Product from "../containers/pages/product/product";
import LotDetails from "../containers/pages/lot/details";
import CheckEmail from "../containers/pages/auth/checkEmail";
import HistoricMovement from "../containers/pages/historicMovement/historicMovement.tsx";
import LoginByInvitation from "../containers/pages/auth/loginByInvitation"
import UpdatePassword from "../containers/pages/profile/update-password";
import SwitchToUser from "../containers/pages/admin/switch-to-user.tsx";

function Root() {

    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/register" element={<Register/>} />
            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/checkEmail" element={<CheckEmail/>} />
            <Route path="/logout" element={<Logout/>}/>
            <Route exact path="/invitation" element={<LoginByInvitation />} />
            <Route exact path="/update-password" element={
                <PrivateRoute>
                    <UpdatePassword />
                </PrivateRoute>
            }
            />
             <Route exact path="/users/:id/switch-to" element={
                <PrivateRoute onlyAdmin>
                    <SwitchToUser />
                </PrivateRoute>
            }
            />
            <Route exact path="/make-proposal/:id" element={
                    <PrivateRoute>
                        <MakeProposal/>
                    </PrivateRoute>
                  }
            />
            <Route exact path="/address" element={
                <PrivateRoute>
                    <MyAddress/>
                </PrivateRoute>
            }
            />
            <Route exact path="/profile" element={
                <PrivateRoute>
                    <Profile />
                </PrivateRoute>
            }
            />
            <Route exact path="/my-products" element={
                <PrivateRoute>
                    <MyProduct />
                </PrivateRoute>
            }
            />
            <Route exact path="/product/:slug" element={
                    <Product />
            }
            />
            <Route exact path="/lot/:id" element={
                <LotDetails />
            }
            />
            <Route exact path="/mineral/historic/:id" element={
                <PrivateRoute>
                    <HistoricMovement />
                </PrivateRoute>
            }
            />
            <Route path="*" element={<NoMatch404 />}/>
        </Routes>
  );
}

export default Root;
