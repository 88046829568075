import axiosInstance from "../axiosInstance";

export async function getMyProducts(callback = null) {
    try {
        const response = await axiosInstance.get('/my-product');
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function createProduct(data, callback = null) {
    try {
        const form = new FormData()
        form.append('name', data.name)
        form.append('description', data.description)
        form.append('weight', data.weight)
        form.append('image', data.image)

        const response = await axiosInstance.post('/product', form, {headers: {
                'content-type': 'multipart/form-data'
            }});
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function getProduct(slug, callback = null) {
    try {
        const response = await axiosInstance.get('/product/'+slug);
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function validateProduct(id, callback = null) {
    try {
        const response = await axiosInstance.post('/product/validate/'+id);
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}