import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {Button, Spin, Form, Input, Row, Radio, notification} from 'antd';
import { Capitalize } from '../../../../utils';
import API from '../../../../services/API'
import { useNavigate  } from "react-router-dom";
import {ArrowLeftOutlined, LoadingOutlined} from '@ant-design/icons';
import FormModel from "../../../../composents/FormModel";
import SelectUserType from "./partials/SelectUserType.tsx";

const antIcon = <LoadingOutlined style={{ fontSize: 75, color: "#023047" }} spin />;

function Register(props) {
  const { t } = useTranslation();
  let navigate = useNavigate ();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [userType, setUserType] = useState('')

  const onFinish = async (values) => {
    setErrorMessage('');
    setLoading(true);
    try {
      await API.register({...values, type: userType})
      notification.success({
        message: "Votre inscription à bien été enregistré, valider votre inscription par email"
      })
      //navigate("/checkEmail", {replace: true}); //No navigate show notification insted
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false);
    }
  };

  const renderLoadSpin = () => {
    return (
        <Row style={{marginTop: 50}}>
          <Spin size='large' indicator={antIcon} style={{margin: 'auto'}}/>
        </Row>
    )
  }

  const registerForm = () => {
    if (loading) {
      return (renderLoadSpin())
    } else {
      return (
        <>
        {
          errorMessage.length > 0 && <div style={{color: 'red'}}>{Capitalize(t(errorMessage))}</div>
        }
          <Row align={'middle'} style={{marginBottom: 20}}>
            <ArrowLeftOutlined style={{fontSize: 20}} onClick={() => setShowForm(false)}/>
            <div style={{margin: 'auto', fontSize: 16}}>{t('auth.register.youAre')}<span style={{color: userType === 'producer' ? '#E87E4D' : '#023047'}}>{t('profile.'.concat(userType))}</span></div>
          </Row>
          <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
          >
            <Form.Item
                label={Capitalize(t('user.email'))}
                name="email"
                rules={[{ required: true, message:  Capitalize(t('auth.please-enter-input')) + t('user.email')  + ' !' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                label={t('user.company')}
                name="company"
                rules={[{ required: true, message:  Capitalize(t('auth.please-enter-input')) + 'company '  + ' !' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                label="Siret"
                name="siret"
                rules={[{ required: true, message:  Capitalize(t('auth.please-enter-input')) + 'siret '  + ' !' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                label={t('user.phone')}
                name="phone"
                rules={[{ required: true, message:  Capitalize(t('auth.please-enter-input')) + 'phone '  + ' !' }, {len: 10, message: "Please enter a valid phone number" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                label={Capitalize(t('auth.password'))}
                name="password"
                rules={[{ required: true, message: Capitalize(t('auth.please-enter-input')) + t('auth.password')  + ' !'}]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item style={{backgroundColor: '#023047'}} wrapperCol={{ offset: 7, span: 8 }} >
              <Button type="text" htmlType="submit" style={{color: 'white'}}>
                {Capitalize(t('common.submit'))}
              </Button>
            </Form.Item>
          </Form>
        </>
      )
    }
  }

  return (
    <div>
      <Row style={{height: 100}}>
        <h2 style={{margin: 'auto'}}>{t('auth.register.title')}</h2>
      </Row>
     <FormModel>
       {showForm ? registerForm() : <SelectUserType onChange={(type) => {
         setUserType(type)
         setShowForm(true)
       }} />}
     </FormModel>
    </div>


  );

}

export default Register
