import React, {useEffect, useState} from 'react';
import {Button, Col, notification, Popconfirm, Row, Space, Table} from "antd";
import API from "../services/API";
import {PlusCircleOutlined} from "@ant-design/icons";
import {finishTransaction, getMyTransaction} from "../services/api/transaction";
import {Capitalize} from "../utils";
import {DateTime} from "luxon";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function TransactionList () {
    const userId = useSelector(state => state.user.id)
    const [transactions, setTransactions] = useState([])
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [columns] = useState([
        {
            title: t('profile.transaction.table.id'),
            dataIndex: "id",
            key: "id"
        },
        {
            title: t('profile.transaction.table.type'),
            dataIndex: "mineralType",
            key: "mineralType",
        },
        {
            title: t('profile.transaction.table.quantity'),
            dataIndex: "quantity",
            key: "quantity",
            render: (_, { quantity }) => (
                <>
                    {quantity} KG
                </>
            ),
        },
        {
            title: t('profile.transaction.table.producer'),
            dataIndex: "producer",
            key: "producer",
            render: (_, { producer, proposal }) => (
                <>
                    {producer.company} / {proposal.from}
                </>
            ),
        },
        {
            title: t('profile.transaction.table.collector'),
            dataIndex: "collector",
            key: "collector",
            render: (_, { collector }) => (
                <>
                    {collector.company}
                </>
            ),
        },
        {
            title: t('profile.transaction.table.status'),
            dataIndex: "status",
            key: "status",
            render: (_, { status, endDate }) => (
                <>
                    {Capitalize(status === 'finish' ? t('profile.transaction.finish-at') + " " + DateTime.fromISO(endDate).toLocaleString(DateTime.DATETIME_SHORT) : status)}
                </>
            ),
        },
        {
            title: t('profile.transaction.table.action'),
            dataIndex: "action",
            key: "action",
            render: (_, { id, status, collector }) => (
                <Space size="middle">
                    {status === 'processing' && collector.id === userId ? <Popconfirm placement="leftTop" title={t('profile.transaction.tooltip-finish')} onConfirm={() => finishTransaction(id)} okText={t('common.yes')} cancelText={t('common.cancel')}>
                        <Button style={{color: "green", borderColor: "green"}}>{t('profile.transaction.finish')}</Button>
                    </Popconfirm>: null}
                </Space>
            ),
        },
    ])

    useEffect(() => {
        getMyTransaction().then()
    }, [])

    const getMyTransaction= async() => {
        try {
            const transactions = await API.getMyTransaction()
            setTransactions(transactions)
        } catch (e) {
            console.warn(e)
        }
    }

    const finishTransaction = async(id) => {
        try {
           await API.finishTransaction(id)
            //notification.success({message: 'Success finish transaction'})
            navigate(0)
        } catch (e) {
            console.warn(e)
            notification.error({message: 'Error on send finish transaction'})
        }
    }

    return(
        <div style={{marginRight: 35, marginLeft: 35, marginTop: 30}}>
            <Row justify="space-between" align="middle">
                <Col span={8}>
                    <h3 style={{fontSize: 36, fontWeight: 700}}>{t('profile.transaction.transaction')}</h3>
                </Col>
            </Row>
            <Table columns={columns} dataSource={transactions} rowKey={'id'} />
        </div>
    )
}