import React, {useEffect, useState} from 'react';
import {Col, Row} from "antd";
import ProfileStats from "../../../composents/Profile/ProfileStats";
import StockList from "../../../composents/StockList.js";
import ProposalList from "../../../composents/ProposalList";
import TransactionList from "../../../composents/TransactionList";
import {useSelector} from "react-redux";
import {Capitalize} from "../../../utils";
import ResourceList from "../../../composents/ResourceList";
import {use} from "i18next";
import {useTranslation} from "react-i18next";

export default function Profile () {
    const company = useSelector(state => state.user.company)
    const userType = useSelector(state => state.user.type)
    const {t} = useTranslation()

    return (
        <div>
            <Row>
                <h1 style={{height: 80, letterSpacing: -1.5, margin: 'auto', fontSize: 48, fontWeight: 700, color:'#023047'}}>
                    {t('profile.welcome')} {company}
                </h1>
            </Row>
            <Row>
                <h2 style={{ letterSpacing: -1.5, margin: 'auto', fontSize: 24, fontWeight: 700, color:"#E87E4D"}}>
                    {userType.map(item => Capitalize(t(`profile.${item}`))).join(' ')}
                </h2>
            </Row>
            <ProfileStats/>
            {
                userType.includes('producer') && <StockList/>
            }
            {
                userType.includes('collector') && <ResourceList/>
            }
            <ProposalList/>
            <TransactionList/>
        </div>
    )
}
