import React, {useEffect, useState} from "react";
import API from "../../../services/API";
import {Col, Row, Table} from "antd";
import MarketMap from "../../../composents/Map/MarketMap";
import ListMineralAvailable from "../../../composents/ListMineralAvailable";
import ListMyAddress from "../../../composents/Address/ListMyAddress";
import {PlusCircleOutlined} from "@ant-design/icons";
import ModalAddress from "../../../composents/Address/ModalAddress";
import {useTranslation} from "react-i18next";

const containerStyle = {
    width: '100%',
    height: '600px'
};


export default function MyAddress() {
    const { t } = useTranslation();
    const [locations, setLocations] = useState([])
    const [loadLocations, setLoadLocations] = useState(false)
    const [center, setCenter] = useState( {
        lat: -3.745,
        lng: -38.523
    })
    const [showModal, setShowModal] = useState(false)
    const [locationToEdit, setLocationToEdit] = useState(null)

    useEffect(() => {
        getMyAddress().then(() => {
            setLoadLocations(false)
        })
    }, [])

    const getMyAddress = async () => {
        setLoadLocations(true)
        const locations = await API.getMyLocations()
        if (locations.length > 0) {
            const firstLocation = locations[0]
            setCenter({ lat: firstLocation.position.coordinates[1], lng: firstLocation.position.coordinates[0] })
        }
        setLocations(locations)
    }

    const showLocationOnMap = (id) => {
        const findLocation = locations.find(location => location.id === id)
        setCenter({ lat: findLocation.position.coordinates[1], lng: findLocation.position.coordinates[0] })
    }

    const showModalEdit = (id) => {
        const findLocation = locations.find(location => location.id === id)
        setLocationToEdit(findLocation)
        setShowModal(true)
    }

    return (
        <div>
            <Row style={{height: 100}} align={'middle'}>
                 <h1 style={{ letterSpacing: -1.5, margin: 'auto', fontSize: 24, fontWeight: 700, color:'#023047'}}>
                     {t("my-location-page.title")}
                 </h1>
                <div style={{color: '#E87E4D', fontSize: 24, fontWeight: 700, right: '15%', position: 'absolute'}}>
                    {t("common.add")} <PlusCircleOutlined style={{cursor: 'pointer'}} onClick={() => setShowModal(true) }/>
                </div>
            </Row>
            <MarketMap center={center} containerStyle={containerStyle} minerals={[]} locations={locations}/>
            <div style={{marginTop: 30, marginRight: 30, marginLeft: 30}}>
                <ListMyAddress locations={locations} load={loadLocations} showLocationOnMap={showLocationOnMap} showModalEdit={showModalEdit}/>
            </div>
            <ModalAddress
                visible={showModal}
                onCancel={() => {
                    setShowModal(false)
                    setLocationToEdit(null)
                }}
                addressToEdit={locationToEdit}
                onOk={() => {
                    setShowModal(false)
                    setLocationToEdit(null)
                    getMyAddress().then(() => {
                        setLoadLocations(false)
                    })
                }}
            />
        </div>
    )
}