import {InfoWindow, Marker} from "@react-google-maps/api";
import React, {useEffect, useState} from "react";


export default function MyAddressMarker({locations, activeMarker, onSetActiveMarker}){
    const renderAddressMarker = (location) => {
        return (
            <Marker
                key={location.id}
                position={{
                    lat: location.position.coordinates[1],
                    lng: location.position.coordinates[0]
                }}
                onClick={() => {
                    onSetActiveMarker(location.id)
                }}
                icon={"https://maps.google.com/mapfiles/ms/icons/blue-pushpin.png"}
            >
                {activeMarker === location.id ? (
                    <InfoWindow key={location.id} onCloseClick={() => {
                        onSetActiveMarker(null)
                    }}>
                        <div>{location.title}</div>
                    </InfoWindow>
                ) : null}
            </Marker>
        )
    }
    return (
        locations.map(mineral => {
            return renderAddressMarker(mineral)
        })
    )
}