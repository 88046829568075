import axiosInstance from '../axiosInstance';

export async function getMyStock( callback = null) {
    try {
        const response = await axiosInstance.get('/stock/minerals');
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function getMineralAroundLocation( callback = null) {
    try {
        const response = await axiosInstance.get('/minerals');
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function getMineralById(id, callback = null) {
    try {
        const response = await axiosInstance.get('/mineral/'+id);
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function getMineralType(callback = null) {
    try {
        const response = await axiosInstance.get('/mineral-type');
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function addMineral(data, callback = null) {
    try {
        const response = await axiosInstance.post('/mineral', {
            typeId: data.typeId,
            quantity: data.quantity,
            locationId: data.locationId
        });
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function putMineralNeedToPickup(mineralId, needToPickup, callback = null) {
    try {
        const response = await axiosInstance.put('/mineral/'+mineralId+'/pickup', {
            needToPickup
        });
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function getHistoricMovement(mineralId) {
    try {
        const response = await axiosInstance.get('/mineral/'+mineralId+'/historic');
        return response.data
    } catch (error) {
        throw error
    }
}