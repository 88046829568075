import React from 'react';
import {plus} from 'hero-patterns'

const hero_div = (
    <div style={{height:500, width:500, backgroundImage:plus('blue', 0.3), position:"absolute", top:-50, right:-100}}></div>
);

const HeroPatternDiv = () => (
    hero_div
);

export default HeroPatternDiv;
