import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {LogoutOutlined, PushpinOutlined, ShopOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";
import {useTranslation} from "react-i18next";

export default function MenuDrop(company, userType) {

    const { t } = useTranslation();
  /*  FOR version 4.20 of Menu cause Menu.Item will be remove
  const items = [
        getItem('My Locations', '/address', <PushpinOutlined />),
        getItem(company, null, <UserOutlined />),
        getItem('Logout', '/logout', <LogoutOutlined />)
    ];

    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }

    return <Menu items={items} style={{fontFamily: 'Bahnschrift', fontSize: 15}} onClick={(item) => console.log(item)} />;
   */
    return  (
        <Menu>
            <Menu.Item key={1}>
                <Link style={{ fontFamily: 'Bahnschrift', fontSize: 15}} to={'/profile'}><UserOutlined/> | {company}</Link>
            </Menu.Item>
            {userType.includes('collector') ? <Menu.Item key={2}>
                <Link style={{fontFamily: 'Bahnschrift', fontSize: 15}} to="/my-products"><ShopOutlined /> | {t("header.my-products")}</Link>
            </Menu.Item>: null}
            <Menu.Item key={3}>
                <Link style={{fontFamily: 'Bahnschrift', fontSize: 15}} to="/address"><PushpinOutlined /> | {t("header.my-locations")}</Link>
            </Menu.Item>
            <Menu.Item key={4}>
                <Link style={{ fontFamily: 'Bahnschrift', fontSize: 15, color: '#E84747'}} to="/logout" ><LogoutOutlined/> | {t("header.logout")}</Link>
            </Menu.Item>
        </Menu>
    );
}