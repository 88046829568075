import React from 'react';
import {Button, Row, Col, Image, Steps, Typography, Space} from "antd";
import image1 from './bouchons-plastiques.jpg';
import image2 from './bouteilles-plastiques.jpg';
import image3 from './caisses-plastiques.jpg';

import plastic_totem from './plastic-totem.png'
import HeroPatternDiv  from '../../../composents/HeroPatternDiv'
import arrow from './arrow-25.svg'

import exemplePng from './home-exemple.jpg'


import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LoupeOutlinedIcon from '@mui/icons-material/LoupeOutlined';
import WhereToVoteOutlinedIcon from '@mui/icons-material/WhereToVoteOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import ShapeLineOutlinedIcon from '@mui/icons-material/ShapeLineOutlined';
import {useNavigate} from "react-router-dom";

const { Step } = Steps;
const { Title } = Typography;

export default function HomeNoConnected () {
    const navigate = useNavigate()
    return (
        <div style={{}}>
            <div style={{ backgroundColor : "white"}}>
                <div style={{width:"75%" , margin:"auto", paddingTop:100}}>
                    <Row>
                        <Col span={12} style={{padding: " 250px 0"}}>
                            <h1 style={{color: "#2C4858", fontWeight:"bold", margin:"auto"}}>Et si vous pouviez savoir précisément ce que deviennent vos <span style={{color : "#E87E4D" }}> déchets plastiques</span> ?</h1>
                            <p>Venez voir ce que propose ECO TRACES</p>
                            <Button onClick={() => navigate('/register')}>Inscription</Button>
                        </Col>
                        <Col span={12}>
                            <HeroPatternDiv/>
                            <img width={200} height={600} src={image1} style={{position: "absolute", objectFit:"cover"}} />
                            <img width={200} height={600} src={image2} style={{position: "absolute", left:220, objectFit:"cover" }} />
                            <img width={200} height={600} src={image3} style={{position: "absolute", left:440, objectFit:"cover", objectPosition: "right" }} />
                            <img style={{position:"absolute", left:-300, bottom:100, opacity:0.3, transform: "scaleY(-1)"}} src={arrow}/>
                        </Col>
                    </Row>
                </div> 
                <div style={{width:"75%" , margin:"auto", paddingTop:30}}>
                    <h1 style={{color: "#2C4858", fontWeight:"bold", fontSize:28}}>Pourquoi la <span style={{color : "#E87E4D" }}> plateforme</span> ECO TRACES ?</h1>
                    <p style={{paddingTop:10}}>
                        Aujourd'hui, personne ne propose de bonne solution de traçabilité entre les producteurs de déchets plastiques et leurs collecteurs.
                        Cela entraine de nombreux problèmes de suivi, fiabilité et transparence. Comment faire en sorte que vos déchets ne finissent pas dans la mer ?
                    </p>
                    <h1 style={{paddingTop:100, paddingBottom:30, textAlign: "center"}}>ECO TRACES vous propose</h1>
                </div>
            </div>
            <div style={{backgroundColor : "#F4F6FC"}}>
                <div style={{width:"75%" , margin:"auto", padding:30, marginTop:20, marginBottom:20}}>
                    <div style={{ borderRadius:20, backgroundColor:'#2C4858', width:"50%"}} >
                        <h2 style={{textTransform: "uppercase", textAlign: "center", color:"white"}}>Pour les producteurs de déchets</h2>
                    </div>
                    <div style={{width:"80%", marginLeft: "auto",paddingTop:20, marginRight:"auto", color:"#2C4858", fontSize:17}}>
                        <p>
                            Vous créez des déchets plastiques dont vous voudriez vous débarrasser simplement et de manière responsable et cela avec un traçabilité mesurée ?
                            Alors <span style={{color:"#E87E4D", fontWeight:"bold"}}>ECO TRACES</span> a été pensé pour vous !
                        </p>
                        <ul style={{marginTop:"20"}}>
                            <li>Vous avez la possibilité de suivre en temps réel ce que deviennent vos déchets ainsi que leur transformation en produits finis mais aussi incinérés ou enfouis. Cependant, vous avez le choix !</li>
                            <li>Les collecteurs qui sont en contact avec vous peuvent vous proposer de créer des produits eco-responsables</li>
                            <li>Vous pouvez communiquer auprès de votre écosystème sur le rayonnement RSE positif</li>
                        </ul>
                    </div>
                    <div style={{marginLeft:20, marginTop:60}}>
                        <Steps style={{marginTop : 20, width: "90%"}}>
                            <Step status="finish" title={<Title style={{color: '#E87E4D',fontSize:20, fontWeight: 'bold', textTransform: "uppercase"}}> Proposition</Title>} icon={<LoupeOutlinedIcon sx={{fontSize: 50 }} style={{  color: '#2C4858', width:50 }} />} />
                            <Step status="finish" title={<Title style={{color: '#E87E4D',fontSize:20, fontWeight: 'bold', textTransform: "uppercase"}}> Relation</Title>} icon={<ConnectWithoutContactOutlinedIcon sx={{fontSize: 50}} style={{ color: '#2C4858' , width:50}} />}/>
                            <Step status="finish" title={<Title style={{color: '#E87E4D',fontSize:20, fontWeight: 'bold', textTransform: "uppercase"}}> Validation</Title>} icon={<TaskAltOutlinedIcon sx={{fontSize: 50}} style={{ color: '#2C4858' , width:50}}/>} />
                            <Step status="finish" title={<Title style={{color: '#E87E4D',fontSize:20, fontWeight: 'bold', textTransform: "uppercase"}}> Collecte</Title>} icon={<LocalShippingOutlinedIcon sx={{fontSize: 50}} style={{  color: '#2C4858' , width:50 }} />} />
                        </Steps>
                        <Row style={{ marginTop:10, textAlign: "justify", fontSize:15}}>
                            <Col span={5}>
                                <p>Vous présentez les matières dont vous voudriez vous séparer sur notre formulaire</p>
                            </Col>
                            <Col span={1}/>
                            <Col span={5}>
                                <p>Vos propositions sont envoyées aux collecteurs potentiels autour de vous qui vont vous faire des offres de récupération</p>
                            </Col>
                            <Col span={1}/>
                            <Col span={5}>
                                <p>Vous recevrez de offres sur la récupération de cos plastiques et vous pourrez choisir ceux à qui vous souhaitez les confier</p>
                            </Col>
                            <Col span={1}/>
                            <Col span={5}>
                                <p>Le collecteur avec lequel vous vous serez mis d'accord viendra directement depuis l'adresse que vous avez défini pour tout vous récupérer</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div style={{backgroundColor:"white"}}>
                <div style={{width:"75%" , margin:"auto", padding:30, marginTop:20, marginBottom:20}}>
                    
                    <div style={{ borderRadius:20, backgroundColor:'#2C4858', width:"50%"}} >
                        <h2 style={{ textTransform: "uppercase", textAlign: "center", color:"white"}}>Pour les collecteurs de déchets</h2>
                    </div>
                    <div style={{width:"80%", marginLeft: "auto",paddingTop:20, marginRight:"auto", color:"#2C4858", fontSize:17}}>
                        <p>
                            Vous utilisez des déchets plastiques comme matière première dans vos produits ?
                            ECO TRACES vous permet d'être en contact direct avec leurs producteurs.
                        </p>
                        <ul>
                            <li>Des propositions détaillées de matières plastiques avec la quantité, le type mais aussi la qualité de chaque plastiques</li>
                            <li>Vous pouvez avoir une relation unique et privilégiée avec votre fournisseur sur le long terme</li>
                            <li>Vous pourrez collecter les matières dont vous avez besoin localement</li>
                            <li>C'est à vous de proposer de récupérer des plastiques quand vous en avez besoin</li>
                        </ul>
                    </div>

                    {
                        /*
                        <div style={{paddingTop:30}}>
                        <Row style={{ textAlign: "justify"}}>
                            <Col span={12}>
                                <Image
                                    src={plastic_totem}
                                    style={{float: "left"}}
                                />
                            </Col>
                            <Col span={12} style={{color:"#2C4858"}}>
                                <div>
                                    <h1 style={{color: '#E87E4D',fontSize:22, fontWeight: 'bold', textTransform: "uppercase"}}>Exemple de support de présentation <span style={{color:"#2C4858",fontSize:18, fontWeight: 'bold', textTransform: "lowercase"}}>(22 Kg)</span></h1>
                                    <p>80% (soit 17,6 Kg) de palettes plastiques de l'entrepôt DECATHLON de Bouc-Bel-Air</p>
                                    <p>15% (soit 3,3 Kg) de bouchons plastiques de la coopérative AGRIFALLEN à Aubagne</p>
                                    <p>5% (soit 1,1 Kg) sont issus d'autres plastiques divers de la région</p>
                                </div>
                            </Col>
                        </Row>
                    </div>


                         */
                    }
                    <div>
                        <Image src={exemplePng} />
                    </div>

                    
                </div>
            </div>
            <div style={{backgroundColor : "#F4F6FC"}}>
                <div style={{width:"75%" , margin:"auto", paddingTop:20, marginBottom:40}}>
                    <h1 style={{color: "#2C4858", fontWeight:"bold", textAlign:"center"}}>Fonctionnalités de la plateforme<span style={{color : "#E87E4D" }}> www.eco-traces.com</span></h1>
                        <div style={{textAlign:"center", marginTop:40}}>
                            <Space size={100} style={{maring:'auto'}}>
                                <div style={{textAlign:'center'}}>
                                   <WhereToVoteOutlinedIcon style={{fontSize:60}}/>
                                    <p style={{color:"white", backgroundColor:'#E87E4D', borderRadius:20, padding:"20px 0", width:250}}>Adaptation aux contraintes d'un écosystème local</p>  
                                </div>
                                <div style={{textAlign:'center'}}>
                                   <RemoveRedEyeOutlinedIcon style={{fontSize:60}}/>
                                    <p style={{color:"white", backgroundColor:'#2C4858', borderRadius:20, textAlign:'center',width:250, padding:"20px 0"}}>Visibilité et gestion des matières plastiques</p>
                                </div>
                                <div style={{textAlign:'center'}}>
                                   <CategoryOutlinedIcon style={{fontSize:60}}/>
                                   <p style={{color:"white", backgroundColor:'#E87E4D', borderRadius:20, textAlign:'center',width:250, padding:"20px 0"}}>Gain de visibilité sur les produits réalisés avec des déchets plastiques</p>
                                </div>
                            </Space>
                            <Space size={100} style={{marginTop:60,maring:'auto'}}>
                                <div style={{textAlign:'center'}}>
                                    <p style={{color:"white", backgroundColor:'#2C4858', borderRadius:20, textAlign:'center',width:250, padding:"20px 0"}}>Composition détaillée et indice de qualité sur les produits finis</p>
                                    <Inventory2OutlinedIcon style={{fontSize:60}}/>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <p style={{color:"white", backgroundColor:'#E87E4D', borderRadius:20, textAlign:'center',width:250, padding:"20px 0"}}>QR codes disponibles pour connaitre les informations d'un produit</p>
                                    <QrCode2OutlinedIcon style={{fontSize:60}}/>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    <p style={{color:"white", backgroundColor:'#2C4858', borderRadius:20, textAlign:'center', padding:"20px 0", width:250}}>Traçabilité entre les lieux de création et de transformation</p>  
                                    <ShapeLineOutlinedIcon style={{fontSize:60}}/>
                                </div>
                            </Space>
                        </div>
                </div>
            </div>
        </div>
    )
}
