import {Button, Card, List, Row} from "antd";
import React from "react";
import {PushpinOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";


export default function ListMyAddress({locations, load, showLocationOnMap, showModalEdit}) {
    const { t } = useTranslation();

    const renderStreet = (location) => {
        if (location.streetAddress) {
            return (
                <div style={{marginTop: 10}}>
                    <div style={{fontSize: 12}}>{location.streetAddress}</div>
                    <div style={{fontSize: 12}}>{location.city}, {location.postalCode}</div>
                </div>
            )
        }
    }
    const renderList = () => {
        return (
            <List
                grid={{
                    gutter: 8,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }}
                dataSource={locations}
                loading={load}
                renderItem={location => {
                    return (
                        <List.Item>
                            <Card  style={{
                                height: 200,
                                position: 'relative',
                            }}>
                                <div style={{
                                    margin: 0,
                                    position: 'absolute',
                                    top: '8%',
                                    right: '2%',
                                    transform: 'translate(-50%, -50%)',
                                }}>
                                    <PushpinOutlined onClick={() => {
                                        showLocationOnMap(location.id)
                                    }} style={{ fontSize: 20}} />
                                </div>
                                <div style={{
                                    margin: 0,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    minWidth: 240,
                                    transform: 'translate(-50%, -50%)',
                                }}>

                                    <div>{location.title}</div>
                                    <div>{location.description}</div>
                                    {renderStreet(location)}
                                    <div style={{backgroundColor: '#023047', width: 130, margin: 'auto', marginTop: 20}}>
                                        <Row align={'middle'}>
                                            <Button type="text" style={{color: 'white', margin: 'auto'}} onClick={() => {
                                                showModalEdit(location.id)
                                            }}>
                                                {t("common.edit")}
                                            </Button>
                                        </Row>

                                    </div>
                                </div>
                            </Card>
                        </List.Item>
                    )
                }}
            />
        )
    }

    return (renderList())
}