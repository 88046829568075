import React, {useEffect, useState}  from 'react';
import {Card, Col, Row} from "antd";
import {useSelector} from "react-redux";
import API from "../../services/API";
import StatCard from "./StatCard";
import {useTranslation} from "react-i18next";

export default function ProfileStats () {
    const userType = useSelector(state => state.user.type)
    const [stockAllTime, setStockAllTime] = useState(0)
    const [mineralTaken, setMineralTaken] = useState(0)
    const [coSaved, setCoSaved] = useState(0)
    const [totalCurrentStock, setTotalCurrentStock] = useState(0)
    const [productCreatedCount, setProductCreatedCount] = useState(0)
    const {t} = useTranslation()

    useEffect(() => {
        getMyStat().then()
    }, [])

    const getMyStat = async() => {
        try {
            const stats = await API.getMyStats()
            setStockAllTime(stats.stockAllTime ? stats.stockAllTime : 0)
            setMineralTaken(stats.mineralTaken)
            setCoSaved(stats.carbonSaved)
            setTotalCurrentStock(stats.totalCurrentStock ? stats.totalCurrentStock : 0)
            setProductCreatedCount(stats.productCreatedCount ? stats.productCreatedCount : 0)
        } catch (e) {
            console.warn(e)
        }
    }

    return (
        <Row gutter={[40, 16]} style={{paddingLeft: 30, paddingRight: 30, paddingTop: 40, justifyContent: 'center'}}>
            {userType.includes('producer') &&
                <Col xs={16} sm={16} md={16} lg={8} xl={8}>
                    <StatCard title={t('profile.stats.mineral-created')} stats={stockAllTime + ' KG'} />
                </Col>
            }
            {userType.includes('producer') &&
                <Col xs={16} sm={16} md={16} lg={8} xl={8}>
                    <StatCard title={t('profile.stats.total-current-stock')} stats={totalCurrentStock + ' KG'} />
                </Col>
            }
            {userType.includes('collector') &&
                <Col xs={16} sm={16} md={16} lg={8} xl={8}>
                    <StatCard title={t('profile.stats.products-created')} stats={productCreatedCount}/>
                </Col>
            }
            {userType.includes('collector') &&
                <Col xs={16} sm={16} md={16} lg={8} xl={8}>
                    <StatCard title={t('profile.stats.minerals-taken')} stats={mineralTaken + ' KG'}/>
                </Col>
            }
            <Col xs={16} sm={16} md={16} lg={8} xl={8}>
                <StatCard title={t('profile.stats.co2-saved')} stats={coSaved + ' KG'} />
            </Col>
        </Row>
    )
}
