import { Form, Input, Button, Row, notification } from 'antd';
import React, { useState} from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../services/API';
import { useNavigate} from "react-router-dom";
import LoadSpin from "../../../composents/LoadSpin";
import { Capitalize } from '../../../utils';
import FormModel from "../../../composents/FormModel";

export default function UpdatePassword() {   
    const { t } = useTranslation();
    let navigate = useNavigate ();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
  
    const onFinish = async (values) => {
      setErrorMessage('');
      setLoading(true);
      try {
        if (values.confirm_password !== values.password) {
            throw { message: "Les mots de passe ne correspondent pas" }
        }
        await API.updatePassword(values.password)
        notification.success({
          message: "Votre mot de passe a été mis à jour"
        })
        navigate("/profile", {replace: true});
      } catch (error) {
        setErrorMessage(error.message)
      } finally {
        setLoading(false);
      }
    };
  
    const renderLoadSpin = () => {
      return (
          <Row style={{marginTop: 50}}>
            <LoadSpin/>
          </Row>
      )
    }
  
    const renderForm = () => {
      if (loading) {
        return (renderLoadSpin())
      } else {
        return (
          <>
          {
            errorMessage.length > 0 && <div style={{color: 'red'}}>{Capitalize(t(errorMessage))}</div>
          }
            <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
            >  
              <Form.Item
                  label={Capitalize(t('auth.password'))}
                  name="password"
                  rules={[{ required: true, message: Capitalize(t('auth.please-enter-input')) + t('auth.password')  + ' !'}]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                  label={Capitalize(t('auth.update-password.confirm_password'))}
                  name="confirm_password"
                  rules={[{ required: true, message: Capitalize(t('auth.update-password.confirm_password'))  + ' !'}]}
              >
                <Input.Password />
              </Form.Item>
  
              <Form.Item style={{backgroundColor: '#023047'}} wrapperCol={{ offset: 7, span: 8 }} >
                <Button type="text" htmlType="submit" style={{color: 'white'}}>
                  {Capitalize(t('common.submit'))}
                </Button>
              </Form.Item>
            </Form>
          </>
        )
      }
    }
  
    return (
      <div>
        <Row style={{height: 100}}>
          <h2 style={{margin: 'auto'}}>{t('auth.update-password.title')}</h2>
        </Row>
       <FormModel>
       {renderForm()}
       </FormModel>
      </div>  
    );
  
};
