import { MenuOutlined, PushpinOutlined , LogoutOutlined} from '@ant-design/icons';
import {Dropdown, Menu } from 'antd';
import React from 'react';
import MenuDrop from "./MenuDropdown";
import {useSelector} from "react-redux";

export default function DropdownButton() {
    const company = useSelector(state => state.user.company)
    const userType = useSelector(state => state.user.type)
    return  <Dropdown.Button overlay={MenuDrop(company, userType)} placement="bottomLeft" icon={<MenuOutlined/>}/>
}