import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import API from "../../../services/API";
import {Card, Col, Image, List, Progress, Row} from "antd";
import LoadSpin, {antIcon} from "../../../composents/LoadSpin";
import CompositionListItem from "../../../composents/Product/CompositionListItem";
import {useTranslation} from "react-i18next";
import {Capitalize} from "../../../utils";

export default function LotDetails() {
    const {id} = useParams()
    const [lot, setLot] = useState(null)
    const [loading, setLoading] = useState(true)
    const {t} = useTranslation()

    useEffect(() => {
        getLot().then(() => setLoading(false) )
    }, [])

    const getLot = async () => {
        try {
            setLoading(true)
            const lot = await API.getLotDetails(id)
            console.log(lot)
            setLot(lot)
        } catch (error) {
            console.warn(error)
        }
    }

    return (
        <div>
            {
                loading ? <Row><LoadSpin/></Row> :
                    <div>
                        <div style={{ paddingLeft: 10}}>
                            <h1 style={{ letterSpacing: -1.5, fontSize: 28, fontWeight: 700, color:'#023047', marginBottom: 0 }}>
                                {lot.product.name}
                            </h1>
                            <h2 style={{ letterSpacing: -1.5, fontSize: 24, fontWeight: 700, color:"#E87E4D"}}>
                                {lot.product.description}
                            </h2>
                            <h1 style={{ letterSpacing: -1.5, fontWeight: 700, color:'#023047'}}>
                                {Capitalize(t('lot.by'))} {lot.product.owner.company}
                            </h1>
                            <h3 style={{fontWeight: 700, marginBottom: 0}}>
                                {t('lot.lot-n')}{lot.id}
                            </h3>
                            <h1 style={{ letterSpacing: -1.5, fontWeight: 700, color:"#E87E4D"}}>
                                {lot.nbCreate} {lot.product.name} {t('lot.in-lot')}
                            </h1>
                            
                        </div>
                        <Row style={{padding: 40}}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Image
                                    src={lot.product.image[0]}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                                            width={200}
                                        />
                                    }
                                />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Row style={{marginBottom: 10}}>
                                    <h3 style={{margin: 'auto', fontSize: 24, fontWeight: 700}}>{t('lot.composition-details')}</h3>
                                </Row>
                                <List
                                    grid={{
                                        gutter: 8,
                                        xs: 1,
                                        sm: 1,
                                        md: 1,
                                        lg: 1,
                                        xl: 1,
                                        xxl: 2,
                                    }}
                                    dataSource={lot.product.compositions}
                                    renderItem={composition => {
                                        let compositionWeight = Math.round(lot.nbCreate * ((lot.product.weight / 100) * composition.percent))
                                        if (compositionWeight < 1) compositionWeight = 1
                                        return (
                                            <List.Item>
                                                <Card>
                                                    <Row>
                                                        <h4 style={{margin: 'auto'}}>{composition.otherMaterial ? composition.otherMaterial : composition.mineralType.alias}</h4>
                                                    </Row>
                                                    <Row>
                                                        <div style={{margin: 'auto'}}>
                                                            <Progress  type="circle" strokeColor={"#E87E4D"} percent={composition.percent} width={80} />
                                                            <span style={{
                                                                margin: 'auto',
                                                                color: "#E87E4D",
                                                                marginLeft: 8,
                                                                fontSize: 14
                                                            }}>soit {compositionWeight} KG</span>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                    </Row>
                                                    {
                                                        composition.otherMaterial ?
                                                            <div>{t('lot.mineral-not-proved')}</div> :
                                                            lot.resources.filter(r => r.type.id === composition.mineralType.id).map(resource => (
                                                                <div>{resource.producer.company}, {resource.fromLocation.title}</div>
                                                            ))

                                                    }
                                                </Card>
                                            </List.Item>
                                        )
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>

            }

        </div>
    )
}