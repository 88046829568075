import React, {useState} from "react";
import {Button, Card, Form, Input, InputNumber, notification, Select, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import API from "../../services/API";
import {useTranslation} from "react-i18next";
import {Capitalize} from "../../utils";


export default function CreateComposition({mineralTypes, product, onSendComposition}) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [mineralTypesSelected, setMineralTypesSelected] = useState([])
    const [loadSending, setLoadSending] = useState(false)

    const onFinish = async (values) => {
        try {
            if (!loadSending) {
                setLoadSending(true)
                for (const composition of values.compositions) {
                    let dto
                    if (composition.mineralTypeName != 'other') {
                        dto = {
                            percent: composition.percent,
                            otherMaterial: null,
                            mineralTypeId: mineralTypes.find(mineral => mineral.alias === composition.mineralTypeName).id,
                        }
                    } else {
                        dto = {
                            percent: composition.percent,
                            otherMaterial: composition.otherMaterial,
                            mineralTypeId: null,
                        }
                    }
                    await API.createComposition(dto, product.id)
                }
                onSendComposition && onSendComposition()
                setLoadSending(false)
            }
        } catch (error) {
            console.warn(error)
            notification.error({message: "Error en create composition"})
        }

    }

    return (
        <Card>
            <Form name="dynamic_form_composition"
                  onFinish={onFinish}
                  autoComplete="off"
                  form={form}
                  onValuesChange={(changedValues, values) => {
                      if (values.compositions) {
                          const newComposition = []
                          for (const composition of values.compositions) {
                              if (composition?.mineralTypeName) {
                                  newComposition.push(composition?.mineralTypeName)
                              }
                          }
                          setMineralTypesSelected(newComposition)
                      }
                  }}

                  layout={form.getFieldsValue()?.compositions?.length > 0 ? 'inline' : 'vertical'}
            >
                <Form.List name="compositions">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => {
                                return (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: 10,
                                            marginTop: 10
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            label={t('composition.mineral-type')}
                                            name={[name, 'mineralTypeName']}
                                            rules={[{required: true, message: "required"}]}
                                        >
                                            <Select style={{minWidth: 170}}>
                                                {
                                                    mineralTypes
                                                        .filter(mineral => !mineralTypesSelected.includes(mineral.alias))
                                                        .map(mineral => <Select.Option key={mineral.id} value={mineral.alias}>{mineral.alias}</Select.Option>)
                                                }
                                                <Select.Option key={'other'} value={'other'}>{t("composition.other-material")}</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            dependencies={[name, 'mineralTypeName']}                                   // if using dependencies
                                            // shouldUpdate={(old, new) => old.hourly !== new.hourly}   // if using shouldUpdate
                                            noStyle
                                        >
                                            {({ getFieldsValue }) => {
                                                const fieldsValues = getFieldsValue()
                                                return fieldsValues?.compositions[name]?.mineralTypeName === 'other' ? (                        // reference getFieldValue here
                                                    <Form.Item
                                                        name={[name, 'otherMaterial']}
                                                        label={t('composition.name')}
                                                        rules={[{required: true, message: "required"}]}
                                                    >
                                                        <Input/>
                                                    </Form.Item>
                                                ) : null
                                            }}
                                        </Form.Item>

                                        <Form.Item
                                            label={t('composition.percentage')}
                                            name={[name, 'percent']}
                                            rules={[{required: true, message: "required"}]}
                                        >
                                            <InputNumber addonAfter={"%"} min={1} max={100}/>
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)}/>
                                    </Space>
                                )}
                            )
                            }
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    {t('common.add')}
                                </Button>
                            </Form.Item>
                            {
                                form.getFieldsValue()?.compositions?.length > 0 &&
                                <Form.Item style={{backgroundColor: '#023047' }} >
                                    <Button type="text" htmlType="submit" style={{color: 'white',  margin: 'auto'}}>
                                        {Capitalize(t('common.submit'))}
                                    </Button>
                                </Form.Item>
                            }

                        </>
                    )}
                </Form.List>

            </Form>
        </Card>
    )
}