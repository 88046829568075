import {Button, Form, Input, InputNumber, Modal, notification, Row, Upload} from "antd"
import {CloseOutlined, UploadOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import API from "../services/API";
import {useTranslation} from "react-i18next";

export default function ModalCreateProduct({visible, onOk, onCancel}) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [photos, setPhotos] = useState([])

    useEffect(() => {
        form.resetFields();    //reset form
    }, [visible])

    const onFinish = async(values) => {
        if (photos.length > 0) {
            try {
                await API.createProduct({...values, image: photos[0].originFileObj})
                onOk && onOk(values)
            } catch (error) {
                console.warn(error)
                notification.error({message: 'Error on create product'})
            }
        }
    }

    return(
        <Modal
            visible={visible}
            centered
            onCancel={() => {
                form.resetFields();    //reset form
                onCancel()
            }}
            bodyStyle={{
                paddingTop: 40,
                borderRadius: 20,
                WebkitBoxShadow: '5px 5px 15px 5px #000000',
                boxShadow: '5px 5px 15px 5px #000000'
            }}
            footer={null}
            className={"modalStyle"}
            closeIcon={<CloseOutlined style={{color: 'white'}}/>}
        >
            <div style={{zIndex: 100, marginLeft: '5%', marginRight: '5%'}}>
                <div style={{
                    backgroundColor: 'white',
                    borderRadius: 20,
                    WebkitBoxShadow: '5px 5px 15px 5px #000000',
                    boxShadow: '5px 5px 15px 5px #000000'
                }}>
                    <Row style={{padding: 25, height: '100%'}} align="middle">
                        <div style={{margin: 'auto', minWidth: '25%'}}>
                            <Row>
                                <h4 style={{margin: 'auto'}}>{t('my-product-page.modal-create.create')}</h4>
                            </Row>
                            <Form
                                style={{marginTop: 10}}
                                name="from_address"
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label={t('my-product-page.modal-create.name')}
                                    name="name"
                                    rules={[{ required: true, message: t('common.required')}]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={t('my-product-page.modal-create.description')}
                                    name="description"
                                    rules={[{ required: true, message: t('common.required')}]}
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item
                                    label={t('my-product-page.modal-create.weight')}
                                    name="weight"
                                    rules={[{ required: true, message: t('common.required') }]}
                                    initialValue={1}
                                >
                                    <InputNumber addonAfter="KG" min={1}/>
                                </Form.Item>

                                <Form.Item
                                    label={t('my-product-page.modal-create.photo')}
                                    required
                                >
                                    <Upload
                                        listType="picture"
                                        accept={".png,.jpeg"}
                                        fileList={photos}
                                        customRequest={() => {
                                            return null
                                        }}
                                        onChange={(info) => {
                                            setPhotos([{...info.file, status: 'done'}])
                                        }}
                                        action={(file) => {}}
                                        beforeUpload={(file, fileList) => {}}
                                    >
                                        <Button icon={<UploadOutlined />}>{t('my-product-page.modal-create.select-photo')}</Button>
                                    </Upload>
                                </Form.Item>

                                <Form.Item style={{backgroundColor: '#023047'}} wrapperCol={{ offset: 8, span: 7 }} >
                                    <Button type="text" htmlType="submit" style={{color: 'white'}}>
                                        {t('my-product-page.modal-create.submit-create')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Row>
                </div>
            </div>
        </Modal>
    )
}