import axiosInstance from "../axiosInstance";

export async function createComposition(data, productId, callback = null) {
    try {
        const response = await axiosInstance.post('/composition', {
            ...data,
            productId
        });
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}


export async function updateComposition(data, compositionId, callback = null) {
    try {
        const response = await axiosInstance.put('/composition/' + compositionId, {
            percent: data.percent
        });
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}