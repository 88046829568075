import {Button, Form, Input, Modal, notification, Row} from "antd"
import {CloseOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import AddressInput from "./AddressInput";
import API from "../../services/API";
import {useTranslation} from "react-i18next";

export default function ModalAddress({visible, onOk, onCancel, addressToEdit}) {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false)
    const [addressSelected, setAddressSelected] = useState(null)
    const [form] = Form.useForm();
    const [showAddressRequireLabel, setShowAddressRequireLabel] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        form.resetFields();
        if (addressToEdit) {
            setEdit(true)
        } else {
            setEdit(false)
        }
    }, [addressToEdit, visible])

    const onFinish = async(values) => {
        console.log(values, edit, addressSelected)
        if (edit) {
            try {
                if (isLoading) return
                setIsLoading(true)
                const params = {
                    ...values,
                    lat: addressSelected ? addressSelected.geometry.location.lat() : addressToEdit.lat,
                    lng: addressSelected ? addressSelected.geometry.location.lng() : addressToEdit.lng,
                    streetAddress: addressSelected ? addressSelected?.formatted_address : addressToEdit.streetAddress,
                    city: addressSelected ? addressSelected.address_components.find(f => f.types.includes('locality'))?.long_name : addressToEdit.city,
                    postalCode: addressSelected ? addressSelected.address_components.find(f => f.types.includes('postal_code'))?.long_name : addressToEdit.postalCode,
                }
                await API.updateLocations(addressToEdit.id, params)
                    .then(() => notification.success({message: "Success update location"}))
                onOk()
            } catch (error) {
                console.warn(error)
                notification.error({
                    message: "Error on update location"
                })
            } finally {
                setIsLoading(false)
            }
        } else {
            if (addressSelected) {
                try {
                    if (isLoading) return
                    setIsLoading(true)
                    await API.createLocations({...values, lat:addressSelected.geometry.location.lat(), lng:addressSelected.geometry.location.lng() })
                        .then(() => notification.success({message: "Success create location"}))
                    onOk()
                } catch (error) {
                    console.warn(error)
                    notification.error({
                        message: "Error on create location"
                    })
                } finally {
                    setIsLoading(false)
                }
            } else {
                setShowAddressRequireLabel(true)
            }
        }

    }

    return(
        <Modal
            visible={visible}
            centered
            onCancel={() => {
                form.resetFields();    //reset form
                onCancel()
            }}
            bodyStyle={{
                paddingTop: 40,
                borderRadius: 20,
                WebkitBoxShadow: '5px 5px 15px 5px #000000',
                boxShadow: '5px 5px 15px 5px #000000'
            }}
            footer={null}
            className={"modalStyle"}
            closeIcon={<CloseOutlined style={{color: 'white'}}/>}
        >
            <div style={{zIndex: 100, marginLeft: '5%', marginRight: '5%'}}>
                <div style={{
                    backgroundColor: 'white',
                    borderRadius: 20,
                    WebkitBoxShadow: '5px 5px 15px 5px #000000',
                    boxShadow: '5px 5px 15px 5px #000000'
                }}>
                    <Row style={{padding: 25, height: '100%'}} align="middle">
                        <div style={{margin: 'auto', minWidth: '25%'}}>
                            <Row>
                                <h4 style={{margin: 'auto'}}>{edit ? t("my-location-page.modal-create.edit") : t("my-location-page.modal-create.create")}</h4>
                            </Row>
                            <Form
                                style={{marginTop: 10}}
                                name="from_address"
                                form={form}
                                layout="vertical"
                                initialValues={{title: addressToEdit?.title, description: addressToEdit?.description}}
                                onFinish={onFinish}
                                onFinishFailed={() => {
                                    if (!edit && !addressSelected) {
                                        setShowAddressRequireLabel(true)
                                    }
                                }}
                            >
                                <Form.Item
                                    label={t("my-location-page.modal-create.title")}
                                    name="title"
                                    rules={[{ required: true, message: t("common.required")}]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={t("my-location-page.modal-create.description")}
                                    name="description"
                                    rules={[{ required: true, message: t("common.required")}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label={t("my-location-page.modal-create.address")}
                                    required
                                >
                                    <AddressInput
                                        initValue={addressToEdit ? addressToEdit.streetAddress : ''}
                                        onSelectAddress={(place) => setAddressSelected(place)}
                                        showRequiredLabel={showAddressRequireLabel}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        loading={isLoading}
                                        type="text"
                                        htmlType="submit"
                                        style={{color: 'white', backgroundColor: '#023047', width: "100%"}}
                                    >
                                        {edit ? t("my-location-page.modal-create.submit-edit") : t("my-location-page.modal-create.submit-create")}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Row>
                </div>
            </div>
        </Modal>
    )
}