import React, {useEffect, useState} from 'react'
import {useParams} from "react-router";
import API from "../../../services/API";
import {Button, Col, Form, Input, InputNumber, notification, Row, Select, Spin} from "antd";
import FormModel from "../../../composents/FormModel";
import LoadSpin from "../../../composents/LoadSpin";
import {useNavigate} from "react-router-dom";
import MarketMap from "../../../composents/Map/MarketMap";
import {DirectionsRenderer, DirectionsService} from "@react-google-maps/api";
import {Duration} from "luxon";
import {useTranslation} from "react-i18next";

export default function MakeProposal() {
    let { id } = useParams();
    const [mineral, setMineral] = useState(null)
    const [locations, setLocations] = useState([])
    const [loadMineral, setLoadMineral] = useState(false)
    const [direction, setDirection] = useState(null)
    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => {
        const getMineral = async () => {
            try {
                const mineralLoad = await API.getMineralById(id)
                setMineral(mineralLoad)
            } catch (err) {
                navigate('/')
            }
        }
        const getMyLocation = async () => {
            try {
                const locations = await API.getMyLocations()
                setLocations([...locations])
            } catch (err) {
                console.warn(err)
            }
        }

        if (!mineral) {
            setLoadMineral(true)
            getMineral().then(() => {
                setLoadMineral(false)
            })
            getMyLocation().then(() => {
            })
        }
    }, [])

    const setDirectionValue = (origin) => {
        const directionsService = new window.google.maps.DirectionsService();
        //const origin = { lat: 43.3085964, lng: 5.4188794 };
        const destination = { lat: mineral.from.position.coordinates[1], lng: mineral.from.position.coordinates[0] }

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    console.log(result)
                    setDirection(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    }

    const renderLoadSpin = () => {
        return (
            <Row style={{marginTop: 50}}>
                <LoadSpin />
            </Row>
        )
    }

    const sendProposal = async(values) => {
        try {
            await API.postProposal({mineralId: mineral.id, quantity: values.quantity})
            notification.success({
                title: "Your proposal as be send"
            })
            setTimeout(() => {
                navigate('/')
            }, 1500);
        } catch (error) {
            console.log(error)
            notification.error({
                title: "Error on make the proposal"
            })
        }
    }

    const renderForm = () => {
        return (
            <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: false }}
                onFinish={sendProposal}
                autoComplete="off"
            >
                <Row style={{marginBottom: 25}}>
                    <h4 style={{color: '#E87E4D'}}>{t('proposal.company')}:</h4>
                    <div style={{paddingLeft: 10}}>{mineral.stock.company} </div>
                </Row>

                <Row style={{marginBottom: 25}}>
                    <h4 style={{color: '#E87E4D'}}>{t('proposal.type')}:</h4>
                    <div style={{paddingLeft: 10}}>{mineral.type.name} </div>
                </Row>

                <Row style={{marginBottom: 25}}>
                    <h4 style={{color: '#E87E4D'}}>{t('proposal.address')}:</h4>
                    <div style={{paddingLeft: 10}}>{mineral.from.streetAddress} </div>
                </Row>

                <Row style={{marginBottom: 25}}>
                    <h4 style={{color: '#E87E4D'}}>{t('proposal.quantity-available')}:</h4>
                    <div style={{paddingLeft: 10}}>{mineral.quantity} KG</div>
                </Row>

                <Form.Item
                    label={t('proposal.quantity')}
                    name="quantity"
                    rules={[{ required: true, message: t('common.required') }]}
                    initialValue={mineral.quantity}
                >
                    <InputNumber addonAfter="KG"/>
                </Form.Item>

                <Form.Item style={{backgroundColor: '#023047'}} wrapperCol={{ offset: 9, span: 8 }} >
                    <Button type="text" htmlType="submit" style={{color: 'white'}}>
                        {t('proposal.send')}
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    const onLoadMap = (map) => {
        /*
        let service = new window.google.maps.places.PlacesService(map);
      //  const latLnaPoint = new window.google.maps.LatLng(mineral.from.position.coordinates[1], mineral.from.position.coordinates[0])

        const latLnaPoint = new window.google.maps.LatLng(43.3085964, 5.4188794)

        console.log(latLnaPoint.lat())

        const request = {
            locationBias: latLnaPoint,
            fields: ['ALL'],
            query: "28 boulevard hugues 13012"
        };

        service.findPlaceFromQuery(request, (results, status) => {
            console.log( status)
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                console.log(results)
                console.log(results[1].photos[0].getUrl())
            }
        });
*/
        if (locations.length > 0) {
            setDirectionValue({
                lat: locations[0].position.coordinates[1],
                lng: locations[0].position.coordinates[0]
            })
        }

    }

    const travelDetails = () => {
        if (locations.length === 0 || !direction ) return
        const dur = Duration.fromObject({ seconds: direction.routes[0].legs[0].duration.value})
        const duration = dur.shiftTo("hours","minutes","seconds").toObject()
        return (
            <div>
                <div>{direction.routes[0].legs[0].distance.text} {t('duration.in')} {duration.hours} {t('duration.hours')} {t('duration.and')} {duration.minutes} {t('duration.minutes')}</div>
                <Select defaultValue={locations[0].title} style={{width: '75%'}} onChange={(id) => {
                    const findLocation = locations.find(loc => loc.id === id)
                    setDirectionValue({
                        lat: findLocation.position.coordinates[1],
                        lng: findLocation.position.coordinates[0]
                    })
                }}>
                    {
                        locations.map(location => <Select.Option key={location.id} value={location.id}>{location.title}</Select.Option>)
                    }
                </Select>

            </div>
        )
    }

    return(
        <div>
            <Row style={{height: 100}}>
                <h2 style={{margin: 'auto'}}>{t('proposal.title')}</h2>
            </Row>
            <FormModel>
            {
                (!loadMineral && mineral ) ?
                    <Row style={{width: '45vw', marginTop: 50}}>
                        <Col flex={2} >
                            <MarketMap
                                onLoadMap={onLoadMap}
                                minerals={[]}
                                center={{ lat: mineral.from.position.coordinates[1], lng: mineral.from.position.coordinates[0] }}
                                locations={[]}
                                containerStyle={{height: 300, width: 300}}
                            >
                                {
                                    direction && <DirectionsRenderer directions={direction} />
                                }
                            </MarketMap>
                            {
                                direction && travelDetails()
                            }
                        </Col>
                        <Col flex={3}>
                            {renderForm()}
                        </Col>
                    </Row>
                    : renderLoadSpin()
            }
            </FormModel>

        </div>
    )
}