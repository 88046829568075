import React, {useEffect, useState} from "react";
import API from "../../../services/API";
import {Button, Card, Col, Image, List, notification, Row} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined, PlusCircleOutlined, PushpinOutlined} from "@ant-design/icons";
import LoadSpin, {antIcon} from "../../../composents/LoadSpin";
import ModalCreateProduct from "../../../composents/ModalCreateProduct";
import {useNavigate} from "react-router-dom";
import {DateTime} from "luxon";
import {useTranslation} from "react-i18next";

export default function MyProduct() {
    const { t } = useTranslation();
    const [products, setProducts] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        getMyProduct().then(() => {
            setLoading(false)
        })
    }, [])

    const getMyProduct = async () => {
        try {
            setLoading(true)
            const products = await API.getMyProducts()
            products.sort((a,b) => {
                return DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis();
            })
            setProducts(products)
        } catch (error) {
            notification.error({message: "Error on load my products"})
        }
    }

    return (
        <div>
            <Row>
                <h1 style={{height: 80, letterSpacing: -1.5, margin: 'auto', fontSize: 48, fontWeight: 700, color:'#023047'}}>
                    {t('my-product-page.title')}
                </h1>
            </Row>
            <Row justify={'end'} style={{marginBottom: 10}}>
                <Col style={{color: '#E87E4D', fontSize: 24, fontWeight: 700, marginRight: 30}}>
                    {t('common.add')} <PlusCircleOutlined style={{cursor: 'pointer'}} onClick={() => {setShowModal(true)}}/>
                </Col>
            </Row>
            <List
                grid={{
                    gutter: 8,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }}
                dataSource={products}
                loading={{size: 'large', indicator:antIcon, style:{margin: 'auto'}, spinning: loading}}
                renderItem={product => {
                    return (
                        <List.Item>
                            <Card  style={{
                                position: 'relative',
                            }}>
                                <Row justify='end'>
                                    {
                                        product.validate ? <CheckCircleOutlined style={{fontSize: 24, color: 'green'}} /> : <CloseCircleOutlined style={{fontSize: 24, color: 'red'}}/>
                                    }
                                </Row>
                                {
                                    product.image && product.image.length > 0 &&
                                    <Row justify="center">
                                        <Image
                                            src={product.image[0]}
                                            width={'15em'}
                                            placeholder={
                                                <Image
                                                    preview={false}
                                                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                                                    width={200}
                                                />
                                            }
                                        />
                                    </Row>
                                }
                                <Row>
                                    <h4 style={{margin: 'auto'}}>{product.name}</h4>
                                </Row>
                                <Row style={{marginTop: 5}}>
                                    <h4 style={{margin: 'auto'}}>{product.description}</h4>
                                </Row>
                                <Row style={{marginTop: 5}}>
                                    <div style={{margin: 'auto', backgroundColor: '#023047'}}>
                                        <Button type="text" style={{color: 'white', margin: 'auto'}} onClick={() => {navigate('/product/'+product.slug)} }>{t('my-product-page.show')}</Button>
                                    </div>
                                </Row>
                            </Card>
                        </List.Item>
                    )
                }}
            />
            <ModalCreateProduct visible={showModal} onCancel={() => setShowModal(false)} onOk={() => {
                setShowModal(false)
                getMyProduct().then(() => {
                        setLoading(false)
                    }
                )
            }} />
        </div>
    )
}