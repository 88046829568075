import React, {useEffect, useState} from 'react'
import {useParams} from "react-router";
import API from "../../../services/API";
import {Row} from "antd";
import LoadSpin from "../../../composents/LoadSpin";
import ProductDetails from "../../../composents/Product/ProductDetails";
import {useSelector} from "react-redux";
import LotProductList from "../../../composents/Product/LotProductList";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";


export default function Product() {
    let { slug } = useParams();
    const [product, setProduct] = useState(null)
    const [loading, setLoading] = useState(true)
    const [isOwner, setIsOwner] = useState(false)
    const [mineralTypes, setMineralTypes] = useState([])

    const userId = useSelector(state => state.user.id)

    useEffect(() => {
        getProductBySlug().then(() => setLoading(false))
        getMineralType().then()
    }, [])

    const getProductBySlug = async () => {
        try {
            setLoading(true)
            const product = await API.getProduct(slug)
            setProduct(product)
            if (product.owner.id === userId) {
                setIsOwner(true)
            }
        } catch (error) {
            console.warn(error)
        }
    }

    const getMineralType = async () => {
        try {
            const types = await API.getMineralType()
            setMineralTypes(types)
        } catch (error) {
            console.warn(error)
        }
    }

    return (
        loading ? <Row><LoadSpin/></Row> : <div  style={{marginRight: 35, marginLeft: 35}}>
            <ProductDetails refresh={() => getProductBySlug().then(() => setLoading(false))} product={product} isOwner={isOwner} mineralTypes={mineralTypes} />
            <LotProductList productId={product.id}/>
        </div>
    )
}