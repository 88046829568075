import React, { useState, useEffect } from 'react';
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import DropdownButton from "./DropdownButton"
import {Space, Button} from "antd";
import SwitchLanguage from "../SwitchLanguage";
import {useTranslation} from "react-i18next";
import logo from "./eco-traces_logo.png";

export default function HeaderBar() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const access_token = useSelector((state) => state.user.access_token)

    // Force rerender
    useEffect(() => {
    }, [access_token])

    const renderLinks = () => {
        if (access_token) {
            return(
                <div style={{height:80}}>
                    <div style={{float:"left"}}>
                        <img style={{height:60, width:260, marginLeft:10}} src={logo}/>
                    </div>

                    <div style={{float:"right"}}>
                    <Space>
                        <p style={{color:'red'}} >CECI EST UNE VERSION BETA</p>
                        <Link to="/" style={{color : "#E87E4D", marginLeft: 20}}>{t("header.marketplace")}</Link>
                        <SwitchLanguage/>
                        <DropdownButton style={{color : "#2C4858"}}> </DropdownButton>
                    </Space>

                    </div>
                </div>

            )
        } else {
            return (
                    <div style={{height:80}}>
                        <div style={{float:"left"}}>
                            <img style={{height:60, width:260, marginLeft:10}} src={logo} onClick={() => navigate('/')}/>
                        </div>
                        <div style={{float:"right"}}>
                            <Space style={{padding:"10px 0", fontWeight:"bold"}}>
                                <Link to="/register" style={{color : "#2C4858", marginLeft: 20, marginRight: 10}}>{t("header.register")}</Link>
                                <Button style={{backgroundColor: "#2C4858", color: 'white', fontSize: 17}} onClick={() => {navigate('/login')}} shape="round">{t("header.login")}</Button>
                                <SwitchLanguage/>
                            </Space>
                        </div>
                    </div>
            )
        }
    }

    return(renderLinks())
}