import React, {useEffect, useState} from 'react';
import {Button, Col, notification, Popconfirm, Row, Space, Table, Popover} from "antd";
import API from "../services/API";
import {Capitalize} from "../utils";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ProposalApplicantPopover from "./ProposalApplicantPopover.tsx";

export default function ProposalList () {
    const [proposal, setProposal] = useState([])
    const navigate = useNavigate()
    const userId = useSelector(state => state.user.id)
    const {t} = useTranslation()

    const [columns] = useState([
        {
            title: t('profile.proposal.table.producer'),
            dataIndex: "producer_company",
            key: "producer_company",
            render: (_, { mineral }) => (
                <>
                    {mineral.stock.company} / {mineral.from.title}, {mineral.from.streetAddress}
                </>
            ),
        },
        {
            title: t('profile.proposal.table.mineral'),
            dataIndex: "type_name",
            key: "type_name",
            render: (_, { mineral }) => (
                <>
                    {mineral.type.alias}
                </>
            ),
        },
        {
            title: t('profile.proposal.table.quantity'),
            dataIndex: "quantity",
            key: "quantity",
            render: (_, { quantity }) => (
                <>
                    {quantity} KG
                </>
            ),
        },
        {
            title: t('profile.proposal.table.quality'),
            dataIndex: "quality",
            key: "quality",
            render: (_, { mineral }) => (
                <>
                    {mineral.quality.score}
                </>
            ),
        },
        {
            title: t('profile.proposal.table.applicant.title'),
            dataIndex: "applicant_company",
            key: "applicant_company",
            render: (_, { id, applicant }) => (
                <ProposalApplicantPopover  proposalId={id} applicant={applicant} />
            ),
        },
        {
            title: t('profile.proposal.table.status'),
            dataIndex: "status",
            key: "status",
            render: (_, { status }) => (
                <>
                    {Capitalize(status)}
                </>
            ),
        },
        {
            title: t('profile.proposal.table.action'),
            dataIndex: "action",
            key: "action",
            render: (_, { id, status, mineral }) => (
                <Space size="middle">
                    {status === 'processing' && mineral.stock.ownerId == userId  ? (
                        <>
                            <Popconfirm placement="leftTop" title={t('profile.proposal.tooltip-validate')} onConfirm={() => approveProposal(id)} okText={t('common.yes')} cancelText={t('common.cancel')}>
                                <Button style={{color: 'green', borderColor: 'green'}}>{t('profile.proposal.validate')}</Button>
                            </Popconfirm>
                            <Popconfirm placement="leftTop" title={t('profile.proposal.tooltip-reject')} onConfirm={() => rejectProposal(id)} okText={t('common.yes')} cancelText={t('common.cancel')}>
                                <Button style={{color: 'red', borderColor: 'red'}}>{t('profile.proposal.reject')}</Button>
                            </Popconfirm>
                        </>

                        ): null}

                </Space>
            ),
        }
    ])

    useEffect(() => {
        getMyProposal().then()
    }, [])

    const getMyProposal= async() => {
        try {
            const proposals = await API.getMyProposal()
            setProposal(proposals)
        } catch (e) {
            console.warn(e)
        }
    }

    const approveProposal = async(id) => {
        try {
            await API.approveProposal(id)
            navigate(0)
        } catch (error) {
            console.warn(error)
            notification.error({message: 'Error on send approve proposal'})
        }
    }

    const rejectProposal = async(id) => {
        try {
            await API.rejectProposal(id)
            navigate(0)
        } catch (error) {
            console.warn(error)
            notification.error({message: 'Error on send reject proposal'})
        }
    }

    return(
        <div style={{marginRight: 35, marginLeft: 35, marginTop: 30}}>
            <Row justify="space-between" align="middle">
                <Col span={8}>
                    <h3 style={{fontSize: 36, fontWeight: 700}}>{t('profile.proposal.proposal')}</h3>
                </Col>
            </Row>
            <Table columns={columns} dataSource={proposal} rowKey={'id'} />
        </div>
    )
}