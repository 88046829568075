import React from "react";
import LoadSpin from '../../../composents/LoadSpin';
import {useQuery} from "@tanstack/react-query";
import { notification, Row } from "antd";
import {useNavigate, useParams} from "react-router";
import API from "../../../services/API";

export default function SwitchToUser () {
    const {id} = useParams()
    const navigate = useNavigate()
    useQuery(
        ['SWITCH_TO', id],
        () => API.switchTo(id),
        {   
            staleTime: 0,
            refetchOnMount: "always",
            onError: (e) => {
                console.error(e)
                notification.error({message: 'Error on switch to user'})
            },
            onSuccess: () => {
                notification.success({message: 'Switch to success'})
                navigate('/profile')
            }
        })


    return ( <Row><LoadSpin /></Row>)
}
