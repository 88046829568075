import Store from '../../redux/store';
import { setLogin } from '../../redux/user/userSlice.ts';
import axiosInstance from '../axiosInstance';
import {handlerErrorApiAuth} from "../../utils/handlerErrorApi";
import {notification} from "antd";

export async function register(params) {
    try {
        const response = await axiosInstance.post('/register', {
                email: params.email,
                company: params.company,
                siret: params.siret,
                password: params.password,
                type: params.type,
                phone: params.phone
        });
        //Store.store.dispatch(setLogin(response.data))
        return response.data
    } catch (error) {
        handlerErrorApiAuth(error)
    }
}


export async function login(params) {
    try {
        const response = await axiosInstance.post('/login', {
            email: params.email,
            password: params.password,
        });
        Store.store.dispatch(setLogin(response.data))
        return response.data
    } catch (error) {
        if (error?.response?.status === 401 && error?.response?.data?.message === "Unauthorized no validate user") {
            notification.error({
                message: "Votre inscription à bien été enregistré, notre équipe reviendra vers vous"
            })
        }
        if (error?.response?.status === 401 && error?.response?.data?.message === "Unauthorized no confirm email") {
            notification.error({
                message: "Merci de confirmer votre email"
            })
        }
        handlerErrorApiAuth(error)
    }
}

export async function checkEmail(token, userId) {
    try {
        const response = await axiosInstance.post('/checkEmail', {
            token: token,
            userId: userId,
        });
        Store.store.dispatch(setLogin(response.data))
        return response.data
    } catch (error) {
        throw error
    }
}

export async function loginByToken(token, userId) {
    try {
        const response = await axiosInstance.post('/auto-login', {
            token: token,
            userId: userId,
        });
        Store.store.dispatch(setLogin(response.data))
        return response.data
    } catch (error) {
        throw error
    }
}

export async function updatePassword(password) {
    try {
        const response = await axiosInstance.post('/update-password', {
            password
        });
      
        return response.data
    } catch (error) {
        throw error
    }
}
