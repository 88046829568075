import React, {useCallback, useEffect, useState} from "react";
import API from "../../../services/API";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import MarketMap from "../../../composents/Map/MarketMap";
import {getMyLocations} from "../../../services/api/location";
import {Row} from "antd";
import ListMineralAvailable from "../../../composents/ListMineralAvailable";
import {useTranslation} from "react-i18next";

const containerStyle = {
    width: '100%',
    height: '600px'
};


export default function Marketplace() {
    const { t } = useTranslation();
    const [minerals, setMinerals] = useState([])
    const [mineralsLoader, setMineralsLoader] = useState(false)
    const [locations, setLocations] = useState([])
    const [center, setCenter] = useState( {
        lat: -3.745,
        lng: -38.523
    })

    useEffect(() => {
        const getMineralAroundLocation = async() => {
            setMineralsLoader(true)
            const mineral = await API.getMineralAroundLocation()
            setMinerals(mineral)
        }
        getMineralAroundLocation().then(() => {
            setMineralsLoader(false)
        })

        const getLocations = async() => {
            const location = await API.getMyLocations()
            if (location.length > 0) {
                const firstLocation = location[0]
                setCenter({ lat: firstLocation.position.coordinates[1], lng: firstLocation.position.coordinates[0] })
            }
            setLocations(location)
        }
        getLocations().then()

    }, [])

    const showMineralOnMap = (id) => {
        const findMineral = minerals.find(mineral => mineral.id === id)
        setCenter({ lat: findMineral.from.position.coordinates[1], lng: findMineral.from.position.coordinates[0] })
    }

    return(
        <div>
            <Row style={{height: 100}}>
                <h1 style={{height: 80, letterSpacing: -1.5, margin: 'auto', fontSize: 48, fontWeight: 700, color:'#023047'}}>
                    {t("marketplace-page.title")}
                </h1>
            </Row>
            <MarketMap center={center} containerStyle={containerStyle} minerals={minerals} locations={locations}/>
            <div style={{marginTop: 30, marginRight: 30, marginLeft: 30}}>
                <ListMineralAvailable minerals={minerals} load={mineralsLoader} showMineralOnMap={showMineralOnMap}/>
            </div>
        </div>
    )
}