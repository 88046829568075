import {Autocomplete} from "@react-google-maps/api";
import React, {useEffect, useState} from "react";
import {Input} from "antd";
import {useTranslation} from "react-i18next";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";


export default function AddressInput({onSelectAddress, initValue, showRequiredLabel = false}) {
    const [autocomplete, setAutocomplete] = useState(null)
    const [value, setValue] = useState('')
    const [fromGoogle, setFromGoogle] = useState(null)
    const { t } = useTranslation();

    useEffect(() => {
        setValue(initValue)
        setFromGoogle(null)
    }, [initValue])

    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete)
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace()
            setFromGoogle(true)
            setValue(place.formatted_address)
            onSelectAddress(place)
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    const onChange = (e) => {
        setFromGoogle(false)
        setValue(e.target.value)
    }

    const requiredLabel = () => {
        if (!value) {
            return <p style={{color: "red"}}>{t("common.required")}</p>
        } else {
            return
        }
    }

    const preffix = fromGoogle !== null ? fromGoogle ? <CheckCircleOutlined style={{color:'green'}} /> : <CloseCircleOutlined style={{color:'red'}} /> : <span />;
    return (
        <>
            <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
            >
                <Input prefix={preffix} placeholder="Enter your address" value={value} onChange={onChange}/>
            </Autocomplete>
            {showRequiredLabel && requiredLabel()}
        </>

    )
}