import React, { useEffect } from 'react';
import { Language } from '../config/lang/Language.ts';
import { useDispatch, useSelector } from 'react-redux';
import { switchLang } from '../redux/app/appSlice.ts';
import { useTranslation } from 'react-i18next';
import {Image, Select} from "antd";
import {Option} from "antd/es/mentions";
import francePng from "./Header/france.png"
import englishPng from "./Header/united-kingdom.png"

function Lang({style}) {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const lang = useSelector((state) => state.app.language)

  useEffect( ()=> {
    const initLanguage = async () => {
      await i18n.changeLanguage(lang);
    }
    initLanguage().then()
  }, [lang])

  let changeLanguage = (changes) => {
    let language = changes.value;
    switch (language) {
      case Language.EN:
        dispatch(switchLang(Language.EN))
        break;
      case Language.FR:
      default:
        dispatch(switchLang(Language.FR))
        break;
    }
  }

  const renderLabel = (language) => {
    switch (language) {
      case Language.EN:
        return <Image width={25} preview={false} src={englishPng} />
        break;
      case Language.FR:
      default:
        return <Image width={25} preview={false} src={francePng} />
        break;
    }
  }

  return (
      <Select
          defaultValue={{ value: lang, label: renderLabel(lang) }}
          labelInValue
          style={{ width: 50 }}
          onChange={changeLanguage}
          showArrow={false}
          bordered={false}
      >
        <Select.Option value={Language.FR}>{renderLabel(Language.FR)}</Select.Option>
        <Select.Option value={Language.EN}>{renderLabel(Language.EN)}</Select.Option>
      </Select>
  )
}

export default Lang;
