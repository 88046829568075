import axiosInstance from '../axiosInstance';

export async function getMyStats( callback = null) {
  try {
    const response = await axiosInstance.get('/dashboard/stats');
    callback && callback()
    return response.data
  } catch (error) {
     throw error
  }
}
