import React, {useMemo, useState} from "react";
import {Card, Image, Popover, Row} from "antd";
import {useQuery} from "@tanstack/react-query";
import API from "../services/API";
import LoadSpin from "./LoadSpin";
import {useTranslation} from "react-i18next";
import BaseCarousel from './UI/BaseCarousel'

type Props = {
    proposalId: string
    applicant: {
        id: string
        company: string
    }
}

export default function ProposalApplicantPopover(props: Props) {
    const { t } = useTranslation()
    const [visible, setVisible] = useState(false)
    const {data: applicantDetails, isLoading, isError} = useQuery(
        ['PROPOSAL_APPLICANT_DETAILS', props.proposalId],
        () => API.getProposalApplicantDetails(props.proposalId),
        {
            staleTime: 1000 * 60,
            enabled: visible
            } //1 minute
        )

    const renderContent = () => {
        if (isLoading) {
            return (
                <div>
                    <Row><LoadSpin /></Row>
                </div>
            )
        }
        if (isError) return <div>Error</div>

        return (
            <div>
                <Row>
                    <div>{t('profile.proposal.table.applicant.nbTransaction')}:</div>
                    <div style={{marginLeft: 10, color: "#E87E4D"}}>{applicantDetails.countTransaction}</div>
                </Row>
                <div>{t('profile.proposal.table.applicant.productAvailable')}:</div>
                <Row align={'middle'} style={{padding: 10}}>
                    <div style={{width: '70%', margin: 'auto'}}>
                        <BaseCarousel data={applicantDetails.productsAvailable} renderItem={(item) => {
                            return (
                                <div key={item.id} style={{width: '100%', justifySelf: 'center', borderColor: 'black', borderWidth: 1, borderStyle: 'solid', borderRadius: 8}}>
                                    <div style={{padding: 5}}>
                                        <div>{item.name}</div>
                                        <Image
                                            height={200}
                                            width={200}
                                            src={item.image[0]}
                                            placeholder={
                                                <Image
                                                    preview={false}
                                                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                                                    width={200}
                                                />
                                            }
                                        />
                                    </div>

                                </div>
                            )
                        }}/>
                    </div>
                </Row>

            </div>
        )
    }

    const renderTitle = () => {
        return <Row><h3 style={{margin: 'auto'}}>{props.applicant.company}</h3></Row>
    }

    return (
        <Popover
            style={{ width: 500 }}
            placement="topLeft"
            content={renderContent}
            title={renderTitle}
            onVisibleChange={(visible) => setVisible(visible)}
        >
            <div style={{color: "#E87E4D"}}>{props.applicant.company}</div>
        </Popover>
    )
}




