import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import Root from '../../app/Root';
import { useSelector } from 'react-redux';
import HeaderBar from "../../composents/Header/HeaderBar";
import Lang from "../../composents/SwitchLanguage";
import {Button, Row, Col, Image, Steps, Space} from "antd";
import FooterImage from './footer-image.png'
import DemoGuard from "../../composents/DemoGuard";

const { Header, Content, Footer, Sider } = Layout;

function LayoutDefault() {
    const demoAccess = useSelector((state) => state.demo.demoAccess)

    useEffect(() => {
    }, [demoAccess])


    const renderWithCheck = () => {
        /*if (demoAccess) {
            return <>
                {renderHeaderBar()}
                <Content style={{marginTop: 40, minHeight: 700, width: '100%', backgroundColor:'#F6F8FB' }}>
                    <Root/>
                </Content>
            </>
        } else {
            return <DemoGuard />
        }*/

                return <>
                    {renderHeaderBar()}
                    <Content style={{marginTop: 40, minHeight: 700, width: '100%', backgroundColor:'#F6F8FB' }}>
                        <Root/>
                    </Content>
                </>

    }

    const renderHeaderBar = () => {
        return (
            <div style={{backgroundColor: '#F6F8FB' }}>
                <div style={{ marginRight : '2%', marginTop: 15, fontSize: 20}} animation={{ y: 30, opacity: 0, type: 'from' }}>
                    <HeaderBar/>
                </div>

                {/*
                <div style={{fontSize: 20, marginLeft: 10}} animation={{ y: 30, opacity: 0, type: 'from' }}>
                    <Lang />
                </div>
                */}
            </div>
        )
    }

    return (
        <Layout className={"App"} style={{ padding: 0, backgroundColor:'#F6F8FB', width: '100%' }}>
            {renderWithCheck()}
            <Footer style={{position: 'relative', paddingTop: 20, marginTop: 20, backgroundColor: 'white'}}>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Space>
                            <img width={100} height={100} src={FooterImage}/>
                            <div style={{position: 'relative', bottom:-10}}>
                                <p>Eco Traces© 2022</p>
                                <p>TDCL Investissement </p>
                                <p>365 Chemin de la République 13420 Gémenos, FRANCE</p>
                            </div>
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <p>CONTACT</p>
                        <p>Stéphane TESTA | <a href="mailto:testastephane@gmail.com">testastephane@gmail.com</a> / <a href="tel:+33786842841">+33 7 86 84 28 41</a></p>
                    </Col>
                </Row>
            </Footer>
        </Layout>
    );
}

export default LayoutDefault;
