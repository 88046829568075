import { createSlice } from '@reduxjs/toolkit'

interface AppState {
    language: "fr"  | 'en',
    demoAccess: boolean
}

const initialState = {
    language: "fr",
    demoAccess: false
} as AppState

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    switchLang(state, action) {
        return { ...state, language: action.payload }
    },
    setDemoAccess(state, action) {
      return { ...state, language: action.payload }
    },
  },
})

export const { switchLang } = appSlice.actions

export default appSlice.reducer