import { Form, Input, Button, Checkbox, Spin, Row, Col } from 'antd';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../services/API';
import {useLocation, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import LoadSpin from "../../../composents/LoadSpin";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

export default function CheckEmail() {

    const { t } = useTranslation();
    let navigate = useNavigate ();
    let location = useLocation();
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const token = searchParams.get('token')
        const userId = searchParams.get('id')
        if (token && userId) {
            sendCheckEmail(token, userId)
        } else {
            setError(true)
            setLoading(false)
        }
    }, [])

    const sendCheckEmail = async (token, userId) => {
        try {
           await API.checkEmail(token, userId)
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    const renderLoading = () => {
        return (
            <div>
                <Row>
                    <h2 style={{margin: 'auto'}}>Vérification de votre mail en cours...</h2>
                </Row>
                <Row style={{marginTop: 20}}>
                    <LoadSpin/>
                </Row>

            </div>

        )
    }

    const renderError = () => {
        return (
            <div>
                <Row>
                    <div style={{margin: "auto"}}>
                        <CloseCircleOutlined style={{color:'red', fontSize: 70 }} />
                    </div>
                </Row>
                <Row style={{marginTop: 20}}>
                    <h2 style={{margin: "auto"}}>Nous n'avons pas pu valider votre email merci de bien suivre le mail qui vous a été envoyer</h2>
                </Row>
            </div>
        )
    }

    const renderSuccess = () => {
        return (
            <div>
                <Row>
                    <div style={{margin: "auto"}}>
                        <CheckCircleOutlined style={{color:'green', fontSize: 70 }} />
                    </div>
                </Row>
                <Row style={{marginTop: 20}}>
                    <h2 style={{margin: "auto"}}>Géniale ! Votre compte est désormais prêt à l'emploie</h2>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Button
                        style={{backgroundColor: "green", color: 'white', fontSize: 16, margin: 'auto'}}
                        onClick={() => navigate("/profile", {replace: true})}
                        shape="round"
                    >
                        Mon profile
                    </Button>
                </Row>
            </div>
        )
    }

    return (
        <div>
            {loading ? renderLoading() : error ? renderError() : renderSuccess() }
        </div>

   )
};
