import React, {useState} from 'react'
import {
    Button,
    Card,
    Col,
    Form,
    Image,
    Input,
    InputNumber,
    List,
    notification,
    Popconfirm,
    Row,
    Select,
    Space
} from "antd";
import CreateComposition from "./CreateComposition";
import {antIcon} from "../LoadSpin";
import CompositionListItem from "./CompositionListItem";
import {CheckCircleOutlined, CloseCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import API from "../../services/API";
import ModalCreateLot from "./ModalCreateLot";
import {useTranslation} from "react-i18next";

export default function ProductDetails({product, isOwner, mineralTypes, refresh}) {
    const { t } = useTranslation();
    const [showModalCreateLot, setShowModalCreateLot] = useState(false)

    const sendValidate = async () => {
        try {
            await API.validateProduct(product.id)
            refresh && refresh()
        } catch (error) {
            console.warn(error)
            notification.error({message: "Error on validate product :" + error.response.data?.message || null})
        }
    }

    return (
        <div>
            <Row style={{height: 100}}>
                <h1 style={{height: 80, letterSpacing: -1.5, margin: 'auto', fontSize: 48, fontWeight: 700, color:'#023047'}}>
                    {product.name} {t('product-page.by')} {product.owner.company}
                </h1>
            </Row>
            <Row>
                <h2 style={{ letterSpacing: -1.5, margin: 'auto', fontSize: 24, fontWeight: 700, color:"#E87E4D"}}>
                    {product.description}
                </h2>
            </Row>
            <Row style={{padding: 40}}>
                <Col span={12}>
                    <Image
                        src={product.image[0]}
                        placeholder={
                            <Image
                                preview={false}
                                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                                width={200}
                            />
                        }
                    />
                </Col>
                <Col span={12}>
                    <Row style={{marginBottom: 10}}>
                        <h3 style={{margin: 'auto', fontSize: 24, fontWeight: 700}}>{t('product-page.composition')}</h3>
                    </Row>
                    <List
                        grid={{
                            gutter: 8,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 3,
                            xl: 3,
                            xxl: 3,
                        }}
                        dataSource={product.compositions}
                        loading={{size: 'large', indicator:antIcon, style:{margin: 'auto'}, spinning: product ? false : true}}
                        renderItem={composition =><CompositionListItem isOwner={isOwner} composition={composition} isProductValidate={product.validate} onUpdate={() => refresh && refresh()}/> }
                    />
                    {
                        isOwner && !product.validate && <CreateComposition mineralTypes={mineralTypes.filter(mineral => !product.compositions.map(comp => comp.mineralType.id).includes(mineral.id))} product={product} onSendComposition={() => refresh && refresh()}/>
                    }
                    { isOwner && <Row align='middle' style={{marginTop: 15}}>
                        {product.validate ?
                            <div style={{margin: 'auto'}}>
                               <div style={{color: '#E87E4D', fontSize: 24, fontWeight: 700}}>
                                   {t("product-page.create-lot")} <PlusCircleOutlined style={{cursor: 'pointer'}} onClick={() => {setShowModalCreateLot(true)}}/>
                               </div>
                                <ModalCreateLot visible={showModalCreateLot} product={product} onCancel={() => setShowModalCreateLot(false)} onOk={() => {
                                    setShowModalCreateLot(false)
                                    refresh && refresh()
                                }}/>
                            </div> :
                            <div style={{margin: 'auto', backgroundColor: '#023047', width: '50%'}}>
                                <Popconfirm placement="topLeft"
                                            title={t('product-page.tooltip-validate-composition')}
                                            onConfirm={sendValidate} okText={t('common.yes')} cancelText={t('common.no')}>
                                    <Button type="text" style={{color: 'white', margin: 'auto', width: '100%'}}>{t('product-page.validate-composition')}</Button>
                                </Popconfirm>
                            </div>
                        }
                    </Row>}
                </Col>
            </Row>
        </div>
    )
}