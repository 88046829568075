import {Button, Card, List} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";
import {PushpinOutlined} from "@ant-design/icons";
import {antIcon} from "./LoadSpin";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


export default function ListMineralAvailable({minerals, load, showMineralOnMap}) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const userType = useSelector(state => state.user.type)
    const userId = useSelector(state => state.user.id)

    const renderList = () => {
        return (
            <List
                grid={{
                    gutter: 8,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }}
                dataSource={minerals}
                loading={{size: 'large', indicator:antIcon, style:{margin: 'auto'}, spinning: load}}
                renderItem={mineral => {
                    return (
                        <List.Item>
                            <Card  style={{
                                height: 200,
                                position: 'relative',
                            }}>
                                <div style={{
                                    margin: 0,
                                    position: 'absolute',
                                    top: '8%',
                                    right: '2%',
                                    transform: 'translate(-50%, -50%)',
                                }}>
                                    <PushpinOutlined onClick={() => showMineralOnMap(mineral.id)} style={{ fontSize: 20}} />
                                </div>
                                <div style={{
                                    margin: 0,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}>

                                    <div>{mineral.stock.company}</div>
                                    <div>{mineral.type.alias}</div>
                                    <div>{mineral.quantity} KG</div>
                                    {
                                        mineral.stock.ownerId !== userId && userType.includes('collector') ?
                                            <div style={{backgroundColor: '#023047', width: 130, margin: 'auto', marginTop: 20}}>
                                                <Button type="text" style={{color: 'white', width: "100%"}} onClick={() => {
                                                    navigate('/make-proposal/'+mineral.id)
                                                }}>
                                                    {t('marketplace-page.make-proposal')}
                                                </Button>
                                            </div> : null
                                    }

                                </div>
                            </Card>
                        </List.Item>
                    )
                }}
            />
        )
    }

    return (renderList())
}