import {InfoWindow, Marker} from "@react-google-maps/api";
import React, {useEffect, useState} from "react";
import {Button, Divider} from "antd";
import {Capitalize} from "../../utils";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


export default function MineralMarker({minerals, activeMarker, onSetActiveMarker}){
    const userType = useSelector(state => state.user.type)
    const userId = useSelector(state => state.user.id)
    const [mineralSort, setMineralSort] = useState([])
    const navigate = useNavigate()
    const { t } = useTranslation();

    useEffect(()=>{
        const address = [...mineralSort]
        for (const mineral of minerals) {
            const findAddress = address.find(addre => addre.id === mineral.from.id)
            const {from,  collectLocation, ...other} = mineral
            if (findAddress) {
                if (!findAddress.minerals.find(mineral => mineral.id === other.id)) {
                    findAddress.minerals.push({...other})
                    setMineralSort([...address.map(addre => {
                        if (addre.id === findAddress.id) {
                            return findAddress
                        } else {
                            return addre
                        }
                    })])
                }
            } else {
                address.push({...mineral.from, minerals: [{...other}]})
                setMineralSort([...address])
            }
        }
    }, [minerals])

    const renderDetailsMarker = (location) => {
        return(
            <div style={{maxHeight: 150, overflowY: 'scroll', width: 200}}>
                {location.minerals.map(mineral => {
                    return (
                        <div key={mineral.id}>
                            <div>
                                <div>{mineral.stock.company}</div>
                                <div>{mineral.type.alias}</div>
                                <div>{mineral.quantity} KG</div>
                                {
                                    mineral.stock.ownerId !== userId && userType.includes('collector') ?
                                        <div style={{backgroundColor: '#023047', width: 130, margin: 'auto', marginTop: 20}}>
                                            <Button type="text" style={{color: 'white'}} onClick={() => {
                                                navigate('/make-proposal/'+mineral.id)
                                            }}>
                                                {t('marketplace-page.make-proposal')}
                                            </Button>
                                        </div> : null
                                }

                            </div>
                            <Divider />
                        </div>
                    )
                })}
            </div>
        )
    }
    const renderMarker = (location) => {
        return (
            <Marker
                key={location.id}
                position={{
                    lat: location.position.coordinates[1],
                    lng: location.position.coordinates[0]
                }}
                clickable
                onClick={() => {
                    onSetActiveMarker(location.id)
                }}
            >
                {activeMarker === location.id && (
                    <InfoWindow key={location.id} onCloseClick={() => {
                        onSetActiveMarker(null)
                    }}>
                        {renderDetailsMarker(location)}
                    </InfoWindow>
                )}
            </Marker>
        )
    }
    return (
        mineralSort.map(mineral => {
            return renderMarker(mineral)
        })
    )
}