import React, {useEffect, useState} from "react";
import API from "../../services/API";
import {Button, Card, Col, List, notification, Row} from "antd";
import {antIcon} from "../LoadSpin";
import {DateTime} from "luxon";
import {useNavigate} from "react-router-dom";
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas';
import {useTranslation} from "react-i18next";
import logo from "../../assets/eco-traces_logo-short.png";



export default function LotProductList({productId}) {
    const [lots, setLots] = useState([])
    const [load, setLoad] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation();

    useEffect(() => {
        getLotsByProductId().then(() => setLoad(false))
    }, [])

    const getLotsByProductId = async () => {
        try {
            setLoad(true)
            const lots = await API.getLotsByProductId(productId)
            setLots(lots)
        } catch (e) {
            console.warn(e)
        }
    }

    const handleDownloadQrCode = (id) => {
        try {
            const svg = document.getElementById('qrcode_'+id)
            html2canvas(svg)
            .then(function (canvas) {
                    const link = document.createElement('a');
                    link.download = 'eco-traces-lot_'+id+'.png';
                    link.href = canvas.toDataURL();
                    link.click();
                });
        } catch (e) {
            console.warn(e)
            notification.error({message: 'Error Download QRCODE'})
        }
    }

    return (
        <div style={{marginTop: 40, marginBottom: 40}}>
            <Row align="middle">
                <Col span={8}>
                    <h3 style={{fontSize: 36, fontWeight: 700}}>{t('lot.title-list')}</h3>
                </Col>
            </Row>
            <List
                grid={{
                    gutter: 8,
                    xs: 1,
                    sm: 3,
                    md: 4,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }}
                dataSource={lots}
                loading={{size: 'large', indicator:antIcon, style:{margin: 'auto'}, spinning: load}}
                renderItem={lot => {
                    return (
                        <Card>
                            <Row>
                                <div style={{margin: 'auto'}}>{t('lot.create-at')} {DateTime.fromISO(lot.createdAt).toLocaleString(DateTime.DATE_SHORT)}</div>
                            </Row>
                            <Row>
                                <div style={{margin: 'auto'}}>{t('lot.nb-create')}: {lot.nbCreate}</div>
                            </Row>
                            <Row style={{marginTop: 5}}>
                                <div style={{margin: 'auto'}}>
                                    <QRCode
                                        id={'qrcode_'+lot.id}
                                        value={window.location.origin +'/lot/'+lot.id}
                                        size={100}
                                        //logoImage={logo}
                                    />
                                </div>
                            </Row>
                            <Row style={{marginTop: 5}}>
                                <Col span={12}>
                                    <div style={{margin: 'auto', width: '60%', backgroundColor: '#E87E4D'}}>
                                        <Button type="text" htmlType="submit" style={{color: 'white',  width: '100%'}}  onClick={() => handleDownloadQrCode(lot.id)}>{t('lot.download')}</Button>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{margin: 'auto', width: '60%', backgroundColor: '#023047'}}>
                                        <Button type="text" htmlType="submit" style={{color: 'white',  width: '100%'}} onClick={() => navigate('/lot/' +lot.id)}>{t('lot.show')}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    )
                }}

            />
        </div>
    )
}