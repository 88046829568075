import axiosInstance from '../axiosInstance';

export async function getMyTransaction(callback = null) {
    try {
        const response = await axiosInstance.get('/transaction');
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function finishTransaction(id, callback = null) {
    try {
        const response = await axiosInstance.put('/transaction/'+id+'/finish');
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}
