import React, {useMemo, useState} from "react";
import API from "../../../services/API";
import {useParams} from "react-router";
import { useQuery } from "@tanstack/react-query";
import {Row, Timeline, Col} from "antd";
import LoadSpin from "../../../composents/LoadSpin";
import {useTranslation} from "react-i18next";
import HistoricMovementTimeline from "./partials/historicMovementTimeline.tsx";

export default function HistoricMovement() {
    const { id } = useParams();
    const { t } = useTranslation()
    const [selectedMovementId, setSelectedMovementId] = useState<string>(null)

    const { data: movements, isError, isLoading} = useQuery(
        ['MOVEMENT_HISTORIC'],
        () => API.getHistoricMovement(id))

    const { data: mineral, isError: isErrorMineral, isLoading: isLoadingMineral} = useQuery(
        ['MINERAL_DETAILS'],
        () => API.getMineralById(id))

    const selectedMovement = useMemo(() => {
        if (selectedMovementId && movements?.length > 0) {
            return movements.find(movement => movement.id === selectedMovementId)
        } else {
            return null
        }
    }, [selectedMovementId])

    if (isLoading || isLoadingMineral) {
        return <Row style={{marginTop: 50}}><LoadSpin /></Row>
    }

    if (isError || isErrorMineral) {
        return null
    }

    return (
        <div>
            <Row style={{height: 100}}>
                <h1 style={{height: 80, letterSpacing: -1.5, margin: 'auto', fontSize: 48, fontWeight: 700, color:'#023047'}}>
                    {t("historic.title")}
                </h1>
            </Row>
            <Row>
                <h2 style={{ letterSpacing: -1.5, margin: 'auto', fontSize: 24, fontWeight: 700, color:"#E87E4D"}}>
                    {t("historic.subtitle", {alias: mineral.type.alias, address: mineral.from.title})}
                </h2>
            </Row>
            <Row style={{marginTop: 30}}>
                {
                    selectedMovement && (
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>

                        </Col>
                    )
                }
                <Col xs={24} sm={24} md={selectedMovement ? 12 : 24} lg={selectedMovement ? 12 : 24} xl={selectedMovement ? 12 : 24}>
                    <HistoricMovementTimeline movements={movements}/>
                </Col>
            </Row>

        </div>
    )
}