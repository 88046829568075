import axiosInstance from '../axiosInstance';

export async function getMyLocations( callback = null) {
    try {
        const response = await axiosInstance.get('/my-address');
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function createLocations(data, callback = null) {
    try {
        const response = await axiosInstance.post('/address', {
            title: data.title,
            description: data.description,
            lat: data.lat,
            lng: data.lng,
            canNotify: true,
            streetAddress: data.streetAddress,
            city: data.city,
            postalCode: data.postalCode
        });
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function updateLocations(id, data, callback = null) {
    try {
        const response = await axiosInstance.put('/address/' + id, {
            title: data.title,
            description: data.description,
            lat: data.lat,
            lng: data.lng,
            canNotify: true,
            streetAddress: data.streetAddress,
            city: data.city,
            postalCode: data.postalCode
        });
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}
