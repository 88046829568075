import {
    useNavigate,
} from 'react-router-dom';
import React, { useEffect } from "react";
import { useSelector } from 'react-redux';

export function PrivateRoute ({children, onlyAdmin}) {

    const access_token = useSelector((state) => state.user.access_token)
    const isAdmin = useSelector((state) => state.user.isAdmin)
    let navigate = useNavigate();


    useEffect(() => {
        if (!access_token) {
            navigate("/login");
        }
        if (onlyAdmin && !isAdmin) {
            navigate("/login");
        }
    })

    return children
}
