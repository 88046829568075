import axiosInstance from '../axiosInstance';

export async function getMyProposal( callback = null) {
    try {
        const response = await axiosInstance.get('/my-proposal');
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function getProposalApplicantDetails( id) {
    try {
        const response = await axiosInstance.get('/proposal/'+id+'/applicant')
        return response.data
    } catch (error) {
        throw error
    }
}

export async function postProposal(data, callback = null) {
    try {
        const response = await axiosInstance.post('/proposal', {
            mineralId: data.mineralId,
            quantity: data.quantity
        });
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function approveProposal(id, callback = null) {
    try {
        const response = await axiosInstance.post('/approve/' +id);
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function rejectProposal(id, callback = null) {
    try {
        const response = await axiosInstance.post('/reject/' +id);
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}
