import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {RightCircleFilled, LeftCircleFilled} from "@ant-design/icons";

export default function BaseCarousel({data, renderItem}) {
    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        fontSize: 30,
        color: "#E87E4D"
    };

    const renderArrowNext = (clickHandler, hasNext, label) => {
        if (hasNext) {
            return (
                <RightCircleFilled onClick={clickHandler} style={{ ...arrowStyles, right: 15 }} />
            )
        }
        return null
    }
    const renderArrowPrev = (clickHandler, hasNext, label) => {
        if (hasNext) {
            return (
                <LeftCircleFilled onClick={clickHandler} style={{ ...arrowStyles, left: 15 }} />
            )
        }
        return null
    }
    return (
        <Carousel showThumbs={false} centerSlidePercentage={100} centerMode showStatus={false} renderArrowNext={renderArrowNext} renderArrowPrev={renderArrowPrev}>
            {data.map(item => renderItem(item))}
        </Carousel>
    )
}