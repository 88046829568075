import React, {useEffect, useState} from 'react';
import {Col, notification, Row, Table} from "antd";
import API from "../services/API";
import {useTranslation} from "react-i18next";

export default function ResourceList () {
    const {t} = useTranslation()
    const [resources, setResources] = useState([])
    const [columns] = useState([
        {
            title: t('profile.resource.table.mineral'),
            dataIndex: "type_name",
            key: "type_name",
            render: (_, { type }) => (
                <>
                    {type.alias}
                </>
            ),
        },
        {
            title: t('profile.resource.table.usable-quantity'),
            dataIndex: "usableQuantity",
            key: "usableQuantity",
            sorter: (a, b) => a.usableQuantity - b.usableQuantity,
            defaultSortOrder: 'descend',
            render: (_, { usableQuantity }) => (
                <>
                    {usableQuantity} KG
                </>
            ),
        },
        {
            title: t('profile.resource.table.transaction'),
            dataIndex: "transactionId",
            key: "transactionId",
        }
    ])

    useEffect(() => {
        getMyResource().then()
    }, [])

    const getMyResource = async() => {
        try {
            const resources = await API.getMyResource()
            setResources([...resources])
        } catch (e) {
            console.warn(e)
        }
    }

    return(
        <div style={{marginRight: 35, marginLeft: 35, marginTop: 30}}>
            <Row justify="space-between" align="middle">
                <Col span={8}>
                    <h3 style={{fontSize: 36, fontWeight: 700}}>{t('profile.resource.resource')}</h3>
                </Col>
            </Row>
            <Table columns={columns} dataSource={resources} rowKey={'id'} />
        </div>
    )
}