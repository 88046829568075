import {Timeline} from "antd";
import React, {useMemo} from "react";
import {DateTime} from 'luxon'
import {useTranslation} from "react-i18next";

type Props = {
    movements: any[]
}
export default function HistoricMovementTimeline(props: Props) {
    const { t } = useTranslation()
    const getColor = (type) => {
        switch (type) {
            case "create":
                return "black"
                break
            case "add":
                return "#E87E4D"
                break
            case "remove":
                return "red"
                break
            case "transaction":
                return "green"
                break
        }
    }

    const renderItem = (movement) => {
        return <Timeline.Item
            key={movement.id}
            color={getColor(movement.type)}
            label={DateTime.fromISO(movement.date).toLocaleString(DateTime.DATE_SHORT)}
        >
            {t("historic.type."+movement.type, {quantity: movement.quantity})}
        </Timeline.Item>
    }

    return (
        <Timeline mode={'left'}>
            {props.movements.map(movement => renderItem(movement))}
        </Timeline>
    )
}