import axiosInstance from "../axiosInstance";
import Store from "../../redux/store";
import {handlerErrorApiAuth} from "../../utils/handlerErrorApi";
import {setDemoAccess} from "../../redux/demo/demoSlice.ts";

export async function sendDemoPassword(params) {
    try {
        const response = await axiosInstance.post('/demo_pass', {
            password: params.password,
        });
        if (response.data.result) {
            Store.store.dispatch(setDemoAccess(response.data.result))
        }
        return response.data
    } catch (error) {
        handlerErrorApiAuth(error)
    }
}