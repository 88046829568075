import React, {useEffect, useState} from 'react';
import { Spin, Row } from 'antd';
import {useSelector} from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import HomeNoConnected from "./homeNoConnected";
import Marketplace from "../marketplace";

export default function Home () {
    const access_token = useSelector((state) => state.user.access_token)
    const [isConnect, setIsConnect] = useState(access_token ? true : false)

    useEffect(()=> {
        if (!isConnect) {
               window.location.href = 'https://eco-traces.fr/'
        }
     
    }, [access_token, isConnect])

    const renderLoadSpin = () => {
        return (
            <Row style={{marginTop: 50}}>
                <Spin size='large' indicator={<LoadingOutlined style={{ fontSize: 75, color: "#023047" }} spin />} style={{margin: 'auto'}}/>
            </Row>
        )
    }

    return (
        <>
            {isConnect ? <Marketplace/> : renderLoadSpin()}
        </>
    )
}
