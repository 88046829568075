import { createSlice } from '@reduxjs/toolkit'

interface DemoState {
    demoAccess: boolean
}

const initialState = {
    demoAccess: false
} as DemoState

const demoSlice = createSlice({
  name: 'demo',
  initialState,
  reducers: {
    setDemoAccess(state, action) {
      return { ...state, demoAccess: action.payload }
    },
  },
})

export const { setDemoAccess } = demoSlice.actions

export default demoSlice.reducer