import Store from '../../redux/store';
import { setLogin } from '../../redux/user/userSlice.ts';
import axiosInstance from '../axiosInstance';

export async function switchTo(id) {
    const response = await axiosInstance.post('/admin/users/'+id+'/switch-to');
    Store.store.dispatch(setLogin(response.data))
    return response.data
}

