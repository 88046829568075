import React, {useState} from "react";
import {Button, Card, Form, InputNumber, List, notification, Progress, Row, Space} from "antd";
import {EditOutlined} from "@ant-design/icons";
import API from "../../services/API";
import {useTranslation} from "react-i18next";
import {Capitalize} from "../../utils";

export default function CompositionListItem({composition, isOwner, isProductValidate, onUpdate}) {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false)

    const sendUpdateComposition = async (values) => {
        try {
            await API.updateComposition(values, composition.id)
            onUpdate && onUpdate()
        } catch (error) {
            console.warn(error)
            notification.error({message: "Error on update composition"})
        }
    }
    return (
        <List.Item>
            <Card>
                {
                    isOwner && !isProductValidate &&
                    <Row justify={'end'} style={{marginBottom: 8}}>
                        <EditOutlined onClick={() => setEdit(!edit)} />
                    </Row>
                }
                <Row>
                    <h4 style={{margin: 'auto'}}>{composition.otherMaterial ? composition.otherMaterial : composition.mineralType.alias}</h4>
                </Row>
                <Row style={{marginTop: 5}} align={'middle'}>
                    {
                        edit ?
                                <Form
                                    initialValues={{percent: composition.percent}}
                                    style={{width: '60%', margin: 'auto', marginTop: 10}}
                                    onFinish={sendUpdateComposition}
                                >
                                    <Form.Item
                                        name={['percent']}
                                        rules={[{required: true, message: t('common.required')}]}
                                    >
                                        <InputNumber addonAfter={"%"} min={1} max={100}/>
                                    </Form.Item>
                                    <Form.Item style={{backgroundColor: '#023047' }} wrapperCol={{ offset: 4, span: 7 }} >
                                        <Button type="text" htmlType="submit" style={{color: 'white'}}>
                                            {Capitalize(t('common.submit'))}
                                        </Button>
                                    </Form.Item>
                                </Form>

                            : <Progress style={{margin: 'auto'}}  type="circle" strokeColor={"#E87E4D"} percent={composition.percent} width={80} />
                    }
                </Row>
            </Card>
        </List.Item>
    )
}