import axiosInstance from '../axiosInstance';

export async function getMyResource( callback = null) {
    try {
        const response = await axiosInstance.get('/resource');
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}
