import React from "react";
import {Card, Row} from "antd";



export default function StatCard({title, stats}) {
    return (
        <Card style={{
            height: 200,
            backgroundColor: 'white',
            borderRadius: 20,
            maxWidth: 400,
            margin: 'auto'
        }}>
            <Row style={{paddingTop: 20}}>
                <div style={{margin: 'auto',fontFamily: 'DM sans', fontWeight: 400, fontSize: 20, color: '#E87E4D'}}>
                    {title}
                </div>
            </Row>
            <Row style={{paddingTop: 19}}>
                <div style={{margin: 'auto',fontFamily: 'DM sans', fontWeight: 700, fontSize: 36}}>
                    {stats}
                </div>
            </Row>
        </Card>
    )
}