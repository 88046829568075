import {Button, Form, Input, InputNumber, Modal, notification, Row, Select, Space, Tooltip, Upload} from "antd"
import {CloseOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import API from "../../services/API";
import LoadSpin from "../LoadSpin";
import {createLot} from "../../services/api/lot";
import {useTranslation} from "react-i18next";

export default function ModalCreateLot({visible, onOk, onCancel, product}) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [resources, setResources] = useState([])
    const [loadResource, setLoadResources] = useState(true)
    const [currentNumber, setCurrentNumber] = useState(1)
    const [formValue, setFormValues] = useState(null)
    const [sendForm, setSendForm] = useState(false)

    useEffect(() => {
        const getResource = async() => {
            try {
                setLoadResources(true)
                const resources = await API.getMyResource()
                setResources(resources)
            } catch (e) {
                console.warn(e)
                notification.error({message: "Fail to get resource"})
            }
        }

        getResource().then(() => setLoadResources(false))
    }, [])

    useEffect(() => {
        form.resetFields();    //reset form
    }, [visible])

    const onFinish = async(values) => {
            if(sendForm) return
            try {
                setSendForm(true)
                let resourcesToLot = []
                const keys = Object.keys(values)
                for(const key of keys) {
                    if (key.includes('resources') && Array.isArray(values[key])) {
                        for (const r of values[key]) {
                            const resourceToLot = {id: r.resourceId.value, quantity: r.quantity}
                            resourcesToLot.push(resourceToLot)
                        }
                    }
                }
                await API.createLot({resources: [...resourcesToLot], nbCreate: values.nbCreate, productId: product.id})
                onOk && onOk(values)
            } catch (error) {
                console.warn(error)
                notification.error({message: 'Error on create lot: \n' + error.response?.data?.message})
            } finally {
                setSendForm(false)
            }
    }

    const getUsableValue = (name, indexResource) => {
        const formValues = formValue
        if (formValues && formValues[name]) {
            const target = {resourceId: null, quantity: null}
            Object.assign(target, formValues[name].at(indexResource))
            if (target.resourceId) return resources.find(item => item.id === target.resourceId.value).usableQuantity
            else return false
        }
        else return false
    }

    const renderNumberResource = (composition, compositionWeight, name) => {
            const usableValue = getUsableValue(`${composition.id}_resources`, name)
            if (usableValue) {
                return (
                    <Form.Item
                        name={[name, 'quantity']}
                        initialValue={compositionWeight <= usableValue ? compositionWeight : usableValue}
                    >
                        <InputNumber addonAfter={'KG'} min={1} max={compositionWeight <= usableValue ? compositionWeight : usableValue}/>
                    </Form.Item>
                )
            } else return null
    }

    return(
        <Modal
            visible={visible}
            centered
            onCancel={() => {
                form.resetFields();    //reset form
                setFormValues(null)
                setCurrentNumber(1)
                onCancel()
            }}
            bodyStyle={{
                paddingTop: 40,
                borderRadius: 20,
                WebkitBoxShadow: '5px 5px 15px 5px #000000',
                boxShadow: '5px 5px 15px 5px #000000'
            }}
            footer={null}
            className={"modalStyle"}
            closeIcon={<CloseOutlined style={{color: 'white'}}/>}
        >
            <div style={{zIndex: 100, marginLeft: '5%', marginRight: '5%'}}>
                <div style={{
                    backgroundColor: 'white',
                    borderRadius: 20,
                    WebkitBoxShadow: '5px 5px 15px 5px #000000',
                    boxShadow: '5px 5px 15px 5px #000000'
                }}>
                    <Row style={{padding: 25, height: '100%'}} align="middle">
                        <div style={{margin: 'auto', minWidth: '25%'}}>
                            <Row>
                                <h4 style={{margin: 'auto'}}>Create a lot</h4>
                            </Row>
                            {
                                loadResource ? <LoadSpin/> : <Form
                                    style={{marginTop: 10}}
                                    name="create_lot"
                                    form={form}
                                    layout="vertical"
                                    onFinish={onFinish}
                                    onValuesChange={(changedValues, values) => {
                                        setFormValues(values)
                                        if (changedValues.hasOwnProperty('nbCreate')) {
                                            setCurrentNumber(changedValues.nbCreate)
                                        }
                                    }}
                                    initialValues={{nbCreate: 1}}
                                >
                                    <Form.Item
                                        label={t("lot.number-to-create")}
                                        name="nbCreate"
                                        rules={[{ required: true, message: "required"}]}
                                    >
                                        <InputNumber min={1} />
                                    </Form.Item>
                                    {
                                        product.compositions.map(composition => {
                                            let compositionWeight = Math.round(currentNumber * ((product.weight / 100) * composition.percent))
                                            if (compositionWeight < 1) compositionWeight = 1

                                            if (composition.otherMaterial) {
                                                return (
                                                    <Form.Item
                                                        key={composition.id}
                                                        label={composition.otherMaterial + ' / ' + compositionWeight + "KG"}
                                                        name={composition.otherMaterial}
                                                        required
                                                    >
                                                        <div>{t('lot.mineral-not-proved')}</div>
                                                    </Form.Item>
                                                )
                                            }

                                            return (
                                                <Form.Item
                                                    key={composition.id}
                                                    label={composition?.mineralType?.alias + ' / ' + compositionWeight + "KG"}
                                                    name={composition.id}
                                                    required
                                                >
                                                    {
                                                        resources.filter(resource => resource.type.id == composition.mineralType.id && resource.usableQuantity != 0).length === 0 ?
                                                            <div style={{color: 'red'}}>{t('lot.insufficient-resource')}</div> :
                                                            <Form.List name={`${composition.id}_resources`}>
                                                                {(fields, { add, remove }) => (
                                                                    <>
                                                                        {fields.map(({ key, name, ...restField }) => {
                                                                            return (
                                                                                <Space
                                                                                    key={key}
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        marginBottom: 10,
                                                                                        marginTop: 10
                                                                                    }}
                                                                                    align="start"
                                                                                >
                                                                                    <Form.Item name={[name, 'resourceId']} >
                                                                                        <Select placeholder={t('lot.resource')} labelInValue>
                                                                                            {
                                                                                                formValue && resources
                                                                                                    .filter(resource => resource.type.id == composition.mineralType.id && resource.usableQuantity != 0 && Array.isArray(formValue[`${composition.id}_resources`]) && !formValue[`${composition.id}_resources`].map(item => item?.resourceId?.value).includes(resource.id) )
                                                                                                    .map(resource => {
                                                                                                        return (
                                                                                                            <Select.Option
                                                                                                                key={resource.id}
                                                                                                                value={resource.id}>
                                                                                                                <Tooltip title={resource.producer.company + "/" + resource.usableQuantity +"KG"} >
                                                                                                                    {resource.producer.company.slice(0,10)} / {resource.usableQuantity} KG
                                                                                                                </Tooltip>
                                                                                                            </Select.Option>
                                                                                                        )

                                                                                                    })
                                                                                            }
                                                                                        </Select>
                                                                                    </Form.Item>
                                                                                    {
                                                                                        renderNumberResource(composition, compositionWeight, name)
                                                                                    }
                                                                                    <MinusCircleOutlined style={{fontSize: 18}} onClick={() => remove(name)}/>
                                                                                </Space>
                                                                            )
                                                                        })}
                                                                        {
                                                                            !formValue ? <Form.Item>
                                                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                                    {t('common.add')}
                                                                                </Button>
                                                                            </Form.Item> : resources.filter(resource =>
                                                                                    resource.type.id == composition.mineralType.id &&
                                                                                    resource.usableQuantity != 0 &&
                                                                                    (
                                                                                        (Array.isArray(formValue[`${composition.id}_resources`]) && !formValue[`${composition.id}_resources`].map(item => item?.resourceId?.value).includes(resource.id))
                                                                                            || !Array.isArray(formValue[`${composition.id}_resources`]))).length > 0 &&
                                                                                <Form.Item>
                                                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                                        {t('common.add')}
                                                                                    </Button>
                                                                                </Form.Item>
                                                                        }
                                                                    </>
                                                                )}
                                                            </Form.List>
                                                    }


                                                </Form.Item>
                                            )
                                        })
                                    }

                                    <Form.Item style={{backgroundColor: '#023047', width: '50%', margin: 'auto'}} >
                                        <Row>
                                            <Button type="text" htmlType="submit" style={{color: 'white', margin: 'auto'}}>
                                                {t('lot.create')}
                                            </Button>
                                        </Row>
                                    </Form.Item>
                                </Form>
                            }

                        </div>
                    </Row>
                </div>
            </div>
        </Modal>
    )
}