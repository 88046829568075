import * as auth from './api/auth'
import * as dashboard from './api/dashboard'
import * as mineral from './api/mineral'
import * as proposal from './api/proposal'
import * as location from './api/location'
import * as transaction from './api/transaction'
import * as product from './api/product'
import * as resource from './api/resource'
import * as composition from './api/composition'
import * as lot from './api/lot'
import * as demo from './api/demo'
import * as admin from './api/admin'

export default {...admin, ...demo, ...lot, ...auth, ...dashboard,...mineral, ...proposal, ...location, ...transaction, ...product, ...resource, ...composition}
