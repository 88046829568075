import './App.css';
import 'antd/dist/antd.min.css';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import LayoutDefault from "../containers/layout";
import {switchLang} from "../redux/app/appSlice.ts";
import {Language} from "../config/lang/Language.ts";

function App() {
  const { i18n } = useTranslation();
  const lang = useSelector((state) => state.app.language);
  const dispatch = useDispatch()

  useEffect(()=> {
    //if (lang != 'en') dispatch(switchLang(Language.EN)) // security force english
    if (i18n.language != lang) i18n.changeLanguage(lang).then();//Init language
  }, []);


  return (
      <LayoutDefault />
  );
}

export default App;
