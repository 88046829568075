import { Form, Input, Button, Checkbox, Spin, Row, Col } from 'antd';
import { Capitalize } from '../../../utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../services/API';
import { useNavigate  } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import FormModel from "../../../composents/FormModel";

const antIcon = <LoadingOutlined style={{ fontSize: 75, color: "#023047" }} spin />;

function Login() {

  const { t } = useTranslation();
  let navigate = useNavigate ();
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setErrorMessage('');
    setLoading(true);
    try {
      await API.login({email: values.email, password: values.password})
      //dispatch(setRememberMe(values.remember)) //pas encore d'utilité
      navigate("/profile", {replace: true});
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false);
    }
  };

  const renderLoadSpin = () => {
      return (
          <Row style={{marginTop: 50}}>
              <Spin size='large' indicator={antIcon} style={{margin: 'auto'}}/>
          </Row>
      )
  }

  return (
      <div>
          <Row style={{height: 100}}>
              <h2 style={{margin: 'auto'}}>{t('auth.login')}</h2>
          </Row>
          <FormModel>
              { loading ?  renderLoadSpin() : <>
                  {
                      errorMessage.length > 0 && <div style={{color: 'red'}}>{Capitalize(t(errorMessage))}</div>
                  }
                  <Form
                      name="basic"
                      layout="vertical"
                      //labelCol={{span: 9}}
                      //wrapperCol={{ span: 16 }}
                      initialValues={{ remember: false }}
                      onFinish={onFinish}
                      autoComplete="off"
                  >
                      <Form.Item
                          label={Capitalize(t('user.email'))}
                          name="email"
                          rules={[{ required: true, message:  Capitalize(t('auth.please-enter-input')) + t('user.email')  + ' !' }]}
                      >
                          <Input />
                      </Form.Item>

                      <Form.Item
                          label={Capitalize(t('auth.password'))}
                          name="password"
                          rules={[{ required: true, message: Capitalize(t('auth.please-enter-input')) + t('auth.password')  + ' !'}]}
                      >
                          <Input.Password />
                      </Form.Item>

                      <Form.Item style={{backgroundColor: '#023047'}} wrapperCol={{ offset: 7, span: 8 }} >
                          <Button type="text" htmlType="submit" style={{color: 'white'}}>
                              {Capitalize(t('common.submit'))}
                          </Button>
                      </Form.Item>
                  </Form>

              </> }
          </FormModel>
      </div>
  );
};

export default Login
