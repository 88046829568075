import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface UserState {
  id: string,
  access_token: string,
  email: string,
  refresh_token: string,
  remember_me: boolean,
  company: string,
  type: string[]
}

const initialState = {
  id: '',
  access_token: null,
  email: "",
  refresh_token: "",
  remember_me: false,
  company: "",
  type: [],
  isAdmin: false
} as UserState

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLogin(state, action) {
        const { token, refreshToken, ...other } = action.payload
        return { ...state, ...other, access_token: token, refresh_token: refreshToken }
    },
    logout(state, action) {
      return { ...initialState}
    },
    setRefreshToken(state, action) {
      const { token, refreshToken } = action.payload
      return { ...state, access_token: token, refresh_token: refreshToken }
    },
    setRememberMe(state, action) {
      return { ...state, remember_me: action.payload}
    }
  },
})

export const { setLogin, logout, setRefreshToken, setRememberMe } = userSlice.actions

export default userSlice.reducer;
