import axiosInstance from "../axiosInstance";

export async function getLotsByProductId(productId, callback = null) {
    try {
        const response = await axiosInstance.get('/'+productId+'/lot');
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}

export async function createLot(data, callback = null) {
    try {
        const response = await axiosInstance.post('/lot', {
            nbCreate: data.nbCreate,
            resources: [...data.resources],
            productId: data.productId
        });
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}


export async function getLotDetails(id, callback = null) {
    try {
        const response = await axiosInstance.get('/lot/' + id);
        callback && callback()
        return response.data
    } catch (error) {
        throw error
    }
}