import {GoogleMap, useJsApiLoader} from "@react-google-maps/api";
import React, {useCallback, useEffect, useState} from "react";
import MineralMarker from "./MineralMarker";
import MyAddressMarker from "./MyAddressMarker";

const libraries = ['places', 'geometry', 'drawing']
export default function MarketMap({center, containerStyle, minerals, locations, onLoadMap, children}) {
    const [map, setMap] = useState(null)
    const [activeMineralMarker, setActiveMineralMarker] = useState([])

    const [activeAddressMarker, setActiveAddressMarker] = useState([])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_API_GOOGLE_MAP_KEY,
        libraries
    })

    const onLoad = useCallback(function callback(map) {
        onLoadMap && onLoadMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onSetActiveMineralMarker = (id) => {
        if (id === activeMineralMarker) {
            return;
        }
        setActiveMineralMarker(id);
    }
    const onSetActiveAddressMarker = (id) => {
        if (id === activeAddressMarker) {
            return;
        }
        setActiveAddressMarker(id);
    }

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={11}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{streetViewControl: false,disableDefaultUI: true, maxZoom: 11 }} // maxZoom 11 for no show other marker on map
        >
            <MineralMarker onSetActiveMarker={onSetActiveMineralMarker} activeMarker={activeMineralMarker} minerals={minerals} />
            <MyAddressMarker onSetActiveMarker={onSetActiveAddressMarker} activeMarker={activeAddressMarker} locations={locations} />
            {children}
        </GoogleMap>
    ) : <></>
}